import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import LinkWrapper from "../../lib/link-wrapper"
import SearchBar from "../search/search-bar"
import AccountLinks from "./account-links"
import CartButton from "./cart-button"
import WishlistButton from "./wishlist-button"

import { getProperty, replaceAll } from "../../lib/utils"

import MenuStructure from "../../data/menuStructure.json"

const MobileMenu = ({ location = {}, logo, wishlistEnabled }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)
  const menuNodeRef = useRef()
  const menuQueryData = useStaticQuery(MENU_QUERY)
  const menuItems = menuQueryData?.seaSiteMenuItems?.header_menu

  useEffect(() => {
    if (typeof document == "undefined") {
      return
    }
    //just backup incase somehow the menu is open and the page
    //content is visible we want to let a touch/click outside
    //the menu area close the menu
    const mouseDownHandler = (event) => {
      if (menuNodeRef.current) {
        if (!menuNodeRef.current.contains(event.target)) {
          setMenuOpen(false)
        }
      }
    }
    if (menuOpen) {
      document.addEventListener("mousedown", mouseDownHandler)
    } else {
      document.removeEventListener("mousedown", mouseDownHandler)
    }

    return () => {
      document.removeEventListener("mousedown", mouseDownHandler)
    }
  }, [menuNodeRef, menuOpen])

  useEffect(() => {
    if (typeof window != "undefined") {
      const bodyNode = document.querySelector("body")
      const fixedClassName = " menu-fixed"
      if (menuOpen) {
        const newScrollPosition = window.pageYOffset
        bodyNode.className += fixedClassName
        bodyNode.style.top = `-${newScrollPosition}px`
        setScrollPosition(newScrollPosition)
      } else {
        bodyNode.className = replaceAll(bodyNode.className, fixedClassName, "")
        bodyNode.style.removeProperty("top")
      }
    }
  }, [menuOpen])

  // Close drawer and scroll to top
  const closeMenu = () => {
    setMenuOpen(false)
    if (typeof window != "undefined") {
      try {
        window.scroll({ top: scrollPosition, behavior: "smooth" })
      } catch (e) {
        window.scrollTo(0, scrollPosition)
      }
      if (!menuOpen && menuNodeRef && menuNodeRef.current) {
        menuNodeRef.current.scrollTo(0, 0)
      }
    }
  }

  return (
    <MobileMenuStyling ref={menuNodeRef} menuOpen={menuOpen}>
      <LogoStyledLinkWrapper to="/">
        <img src={logo} />
      </LogoStyledLinkWrapper>
      <CartWishlistStyling>
        {wishlistEnabled && <WishlistButton />}
        <CartButton />
      </CartWishlistStyling>
      <BurgerStyledButton
        menuOpen={menuOpen}
        onClick={(e) => {
          if (menuOpen) {
            closeMenu()
          } else {
            setMenuOpen(true)
          }
          e.preventDefault()
        }}
      >
        <div />
        <div />
        <div />
      </BurgerStyledButton>
      <NavigationStyledNav menuOpen={menuOpen}>
        <SearchBar />
        {menuItems.map((node, i) => {
          let className = ""
          if (node.url === "/" && location?.pathname === "/") {
            className = "hide-home-button"
          }
          return (
            <div key={i} className={className}>
              <MenuItemStyledLinkWrapper
                to={node.url}
                as={node.url ? LinkWrapper : "div"}
              >
                {node.title}
              </MenuItemStyledLinkWrapper>
            </div>
          )
        })}
        <AccountLinks />
        <MobileMenuBottomSpacer />
      </NavigationStyledNav>
    </MobileMenuStyling>
  )
}

export default MobileMenu

// ===============
//     STYLES
// ===============

const LogoStyledLinkWrapper = styled(LinkWrapper)`
  height: 72px;
  position: absolute;
  left: 30px;
  z-index: 1;
  width: auto;
  display: flex;
  align-items: center;
  img {
    vertical-align: middle;
    max-height: 50px;
    margin: 0;
  }
`

const MobileMenuBottomSpacer = styled.div`
  height: 100px !important;
`

const MenuItemStyledLinkWrapper = styled(LinkWrapper)`
  color: white;
  font-family: ${({ theme }) => theme.primaryFont};
  font-size: 1rem;
  text-align: center;
  text-shadow: ${({ theme }) => theme.boxShadow};
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  :active {
    background: ${({ theme }) => theme.colors.brandHover};
    font-weight: bold;
  }
`

const MobileMenuStyling = styled.div`
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  height: ${({ menuOpen }) => (menuOpen ? "100vh" : "4.5rem")};
  overflow: ${({ menuOpen }) => (menuOpen ? "auto" : "hidden")};
  transition: all 0.3s ease-in-out;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  background: ${({ theme }) => theme.colors.brandPrimary};
  @media (min-width: calc(${({ theme }) => theme.breakLarge} + 1px)) {
    display: none;
  }
  .cart-text {
    display: none;
  }

  .not-a-link {
    display: none;
  }
`

const BurgerStyledButton = styled.button`
  position: absolute;
  top: 1.25rem;
  right: 30px;
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    background: transparent;
  }
  div {
    position: relative;
    transform-origin: 0 center;
    transition: all 0.3s linear;
    width: 30px;
    height: 3px;
    padding: 0;
    border-radius: 5px;
    margin: 0;
    background: white;
    :first-child {
      transform: ${({ menuOpen }) =>
        menuOpen ? "rotate(45deg)" : "rotate(0)"};
    }
    :nth-child(2) {
      opacity: ${({ menuOpen }) => (menuOpen ? "0" : "1")};
    }
    :nth-child(3) {
      transform: ${({ menuOpen }) =>
        menuOpen ? "rotate(-45deg)" : "rotate(0)"};
    }
  }
`

const NavigationStyledNav = styled.nav`
  position: relative;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  margin-top: 4.5rem;
  text-align: center;

  a {
    text-transform: uppercase;
    width: 100%;
    transition: all 0.3s ease-in-out;
    color: white;
    opacity: ${({ menuOpen }) => (menuOpen ? "1" : "0")};
  }

  & > div:hover {
    background: ${({ theme }) => theme.colors.brandHover};
  }

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightgrey3};
    background: ${({ theme }) => theme.colors.brandPrimaryLight};
  }

  .hide-home-button {
    display: none;
  }
`

const CartWishlistStyling = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  width: -webkit-fill-available;
  height: 72px;
  margin-right: 75px;
`

// ===============
//      QUERY
// ===============

const MENU_QUERY = graphql`
  {
    seaSiteMenuItems {
      header_menu {
        title
        url
      }
    }
  }
`
