import React from "react"

function Telegram() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.49"
      height="23.893"
      viewBox="0 0 28.49 23.893"
    >
      <path
        fill="#03111f"
        d="M28.41 6.7l-4.3 20.275c-.324 1.431-1.17 1.787-2.372 1.113l-6.551-4.827-3.16 3.039a1.645 1.645 0 01-1.316.642l.471-6.671L23.322 9.3c.528-.471-.114-.731-.82-.261l-15.01 9.455-6.462-2.022c-1.406-.439-1.431-1.406.293-2.08L26.6 4.655c1.17-.439 2.194.261 1.813 2.048z"
        data-name="Icon awesome-telegram-plane"
        transform="translate(-.001 -4.528)"
      ></path>
    </svg>
  )
}

export default Telegram
