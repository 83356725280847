import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"

import { windowWidths } from "../theme/theme-config"

import DrawerProductTable from "./drawer-product-table"
import { setBodyScrollLockHook } from "../hooks/set-body-scroll-lock-hook"

import { replaceAll } from "../lib/utils"
import LinkWrapper from "../lib/link-wrapper"

export const drawerSpacing = "30px"

const Drawer = ({
  location,
  className = "",
  subtitle = "",
  drawerType = "Cart",
  drawerOpen,
  isDrawerPage = false,
  drawerContents = false,
  loading = false,
  setDrawerOpen = () => {},
  removeItem = () => {},
  updateItem = () => {},
  moveItemToCart = false,
  moveAllItemsToCart = false,
  hideProductPrice = false,
  hideProductQuantity = false,
  children = null,
}) => {
  const [message, setMessage] = useState("")
  const [scrollPosition, setScrollPosition] = useState(0)
  const drawerNodeRef = useRef()
  setBodyScrollLockHook(drawerOpen)

  let createClassNameString = ""

  if (isDrawerPage) {
    createClassNameString = `${createClassNameString} drawer-page-styling`

    if (
      typeof window != "undefined" &&
      window?.screen?.width < windowWidths.mobileLarge
    ) {
      createClassNameString = `${createClassNameString} page-fullwidth-section`
    }
  }

  useEffect(() => {
    if (typeof document == "undefined") {
      return
    }
    //just backup incase somehow the menu is open and the page
    //content is visible we want to let a touch/click outside
    //the menu area close the menu
    const mouseDownHandler = (event) => {
      if (drawerNodeRef.current) {
        if (!drawerNodeRef.current.contains(event.target)) {
          setDrawerOpen(false)
        }
      }
    }
    if (drawerOpen) {
      document.addEventListener("mousedown", mouseDownHandler)
    } else {
      document.removeEventListener("mousedown", mouseDownHandler)
    }

    return () => {
      document.removeEventListener("mousedown", mouseDownHandler)
    }
  }, [drawerNodeRef, drawerOpen])

  useEffect(() => {
    if (typeof window != "undefined") {
      const bodyNode = document.querySelector("body")
      const fixedClassName = ` drawer-fixed`
      if (drawerOpen && !isDrawerPage) {
        const newScrollPosition = window.pageYOffset
        bodyNode.className += fixedClassName
        bodyNode.style.top = `-${newScrollPosition}px`
        setScrollPosition(newScrollPosition)
      } else {
        bodyNode.className = replaceAll(bodyNode.className, fixedClassName, "")
        bodyNode.style.removeProperty("position")
        bodyNode.style.removeProperty("top")
        bodyNode.style.removeProperty("overflow")
        bodyNode.style.removeProperty("width")
      }
    }
  }, [drawerOpen])

  // Close drawer and scroll to top
  const closeDrawer = () => {
    setDrawerOpen(false)
    if (typeof window != "undefined") {
      try {
        window.scroll({ top: scrollPosition, behavior: "smooth" })
      } catch (e) {
        window.scrollTo(0, scrollPosition)
      }
    }
  }

  // Remove an item completely
  const removeItemFromDrawer = (product) => {
    console.log("Removing: ", product)
    setMessage("")
    removeItem(product).catch((error) => {
      if (error && error.message) {
        setMessage(error.message)
      } else {
        setMessage("Failed to complete action")
      }
    })
  }

  // Update an item with a new quantity
  const updateItemInDrawer = (product, quantity) => {
    console.log("Updating: ", product)
    setMessage("")
    updateItem(product, quantity).catch((error) => {
      if (error && error.message) {
        setMessage(error.message)
      } else {
        setMessage("Failed to complete action")
      }
    })
  }

  // Decrease item quantity by 1
  const decrementItemInDrawer = (product) => {
    if (product.quantity > 1) {
      updateItemInDrawer(product, product.quantity - 1)
    } else {
      removeItemFromDrawer(product)
    }
  }

  // Increase item quantity by 1
  const incrementItemInDrawer = (product) =>
    updateItemInDrawer(product, product.quantity + 1)

  return (
    <>
      <BodyOverflow className={createClassNameString} drawerOpen={drawerOpen} />
      <DrawerStyling
        className={`${className} ${createClassNameString} page-fullwidth-section`}
        ref={drawerNodeRef}
        drawerOpen={drawerOpen}
        isDrawerPage={isDrawerPage}
      >
        <DrawerHeadingsStyling drawerSpacing={drawerSpacing}>
          <CloseButton
            className={createClassNameString}
            onClick={() => {
              isDrawerPage
                ? navigate(
                    location.state && location.state.fromUrl
                      ? `${location.state.fromUrl}`
                      : "/"
                  )
                : closeDrawer()
            }}
          >
            &#9587;
          </CloseButton>
          <StyledH2>{`Your ${drawerType}`}</StyledH2>
          {!subtitle ? null : <StyledH3>{subtitle}</StyledH3>}
          <Styledh5
            onClick={() => {
              closeDrawer()
              navigate("/shop/")
            }}
          >
            &#8592; Back to shop
          </Styledh5>
        </DrawerHeadingsStyling>
        {!drawerContents || !drawerContents.length ? (
          <EmptyDrawerMessageStyling drawerSpacing={drawerSpacing}>
            <div>{`Your ${drawerType} is empty`}</div>
            <div>
              Visit our
              <LinkWrapper to={`/shop/`} onClick={closeDrawer}>
                shop page
              </LinkWrapper>
              to see our full catalogue
            </div>
          </EmptyDrawerMessageStyling>
        ) : (
          <>
            <DrawerProductTable
              products={drawerContents}
              loading={loading}
              closeDrawer={closeDrawer}
              removeItemFromDrawer={removeItemFromDrawer}
              decrementItemInDrawer={decrementItemInDrawer}
              incrementItemInDrawer={incrementItemInDrawer}
              hideProductPrice={hideProductPrice}
              hideProductQuantity={hideProductQuantity}
              moveItemToCart={moveItemToCart}
              moveAllItemsToCart={moveAllItemsToCart}
              drawerSpacing={drawerSpacing}
            />
            {!message ? null : (
              <ErrorMessage
                dangerouslySetInnerHTML={{ __html: message }}
                drawerSpacing={drawerSpacing}
              />
            )}
            {children}
          </>
        )}
      </DrawerStyling>
    </>
  )
}

export default Drawer

// ===============
//     STYLES
// ===============
const ErrorMessage = styled.div`
  color: red;
  padding: 0 ${({ drawerSpacing }) => drawerSpacing} 20px;
`
const EmptyDrawerMessageStyling = styled.div`
  padding: 0 ${({ drawerSpacing }) => drawerSpacing} 20px;
  background-color: white;
  height: 100%;
  text-align: center;
  a {
    font-weight: 600;
    padding: 0 5px;
  }
`

const DrawerStyling = styled.div`
  position: fixed;
  top: 0;
  display: block;
  z-index: 102;
  overflow: ${({ drawerOpen }) => (drawerOpen ? "auto" : "hidden")};
  transition: all 0.3s ease-in-out;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  background: ${({ theme }) => theme.colors.lightgrey1};
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakSmall}) {
    max-width: 650px;
    left: ${({ drawerOpen }) => (drawerOpen ? "calc(100% - 650px)" : "100vw")};
    transform: translateX(0);
    height: 100vh;
    transition-property: left, overflow;
    overscroll-behavior: contain;
  }
  height: ${({ isDrawerPage }) => (isDrawerPage ? "100%" : "0")};
  &.drawer-page-styling {
    height: 100%;
    position: static;
    top: unset;
    max-width: 800px;
    overflow: visible;
    @media (min-width: ${({ theme }) => theme.breakSmall}) {
      margin-left: auto;
      margin-right: auto;
    }
    h2 {
      padding-top: 0rem;
    }
  }
`

const CloseButton = styled.button`
  position: absolute;
  display: block;
  padding: 0;
  height: 50px;
  width: 50px;
  min-width: 50px;
  min-height: 50px;
  border-radius: 100%;
  margin-top: 30px;
  line-height: 1rem;
  &.drawer-page-styling {
    top: -20px;
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    display: none;
  }
`

const DrawerHeadingsStyling = styled.div`
  position: relative;
  padding: 10px ${({ drawerSpacing }) => drawerSpacing} 50px;
  background-color: white;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    padding: 30px;
  }
`

const StyledH3 = styled.h3`
  color: ${({ theme }) => theme.colors.brandPrimary};
  text-align: center;
  font-size: 1.7rem;
  text-transform: none;
`

const StyledH2 = styled.h2`
  text-align: center;
  padding-top: 40px;
`

const BodyOverflow = styled.div`
  position: fixed;
  width: 100%;
  background: ${({ theme }) => theme.colors.blue}66;
  backdrop-filter: blur(8px);
  height: 100vh;
  z-index: 101;
  top: 0;
  opacity: ${({ drawerOpen }) => (drawerOpen ? "1" : "0")};
  transform: ${({ drawerOpen }) =>
    drawerOpen ? "translateX(0)" : "translateX(100%)"};
  transition: opacity 0.3s ease-in-out, transform 0s linear 0.5s;
  transition-property: ${({ drawerOpen }) =>
    drawerOpen ? "opacity" : "opacity transform"};

  &.drawer-page-styling {
    display: none;
  }
`
const Styledh5 = styled.h5`
  color: ${({ theme }) => theme.colors.brandPrimary};
  text-align: center;
  font-size: 18px;
  font-weight: normal;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 10px;
`
