import React from "react"

const Wishlist = ({ stroke, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32.355"
    height="28.348"
    viewBox="0 0 32.355 28.348"
  >
    <g id="Wishlist_2" data-name="Wishlist 2" transform="translate(0.5 0.5)">
      <path
        id="Icon_feather-heart"
        data-name="Icon feather-heart"
        d="M31.26,6.915a8.25,8.25,0,0,0-11.67,0L18,8.505l-1.59-1.59A8.252,8.252,0,1,0,4.74,18.585l1.59,1.59L18,31.845l11.67-11.67,1.59-1.59a8.25,8.25,0,0,0,0-11.67Z"
        transform="translate(-2.323 -4.497)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>
)

export default Wishlist
