import React, { useContext, memo } from "react"
import styled from "styled-components"
import LinkWrapper from "../../lib/link-wrapper"

import CartContext from "../../context/cart/cart-context"

import Cart from "../../images/icons/cart"

// ======================
//      COMPONENT
// ======================
const CartButton = memo(({ className = "" }) => {
  const { cartCount, openCartDrawer } = useContext(CartContext)

  return (
    <StyledLinkWrapper
      className={className}
      onClick={(e) => {
        openCartDrawer(true)
        e.preventDefault() // Do not perform default "LinkWrapper" navigate
      }}
    >
      <CartStyling className="cart-icon">
        {cartCount > 0 && <span className="notify">{cartCount}</span>}
        <Cart />
      </CartStyling>
      <span className="cart-text">My Cart</span>
    </StyledLinkWrapper>
  )
})

export default CartButton

// ===============
//      STYLES
// ===============
const CartStyling = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  & span {
    text-align: center;
    line-height: 1rem;
    position: absolute;
    width: 100%;
    font-size: 0.8rem;
  }
  svg {
    margin: 0 10px 0 5px;
  }
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    margin: 0 10px 0 4px;
  }
`
const StyledLinkWrapper = styled(LinkWrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin: 0 10px 0 2px;
  }
  :hover svg g path {
    fill: white;
    transition: all 0.15s ease-in-out;
  }
  svg g path {
    fill: transparent;
    transition: all 0.15s ease-in-out;
  }
  &:hover span:not(.cart-text) {
    color: ${({ theme }) => theme.colors.blue};
    transition: all 0.15s ease-in-out;
  }
  & span {
    color: white;
    transition: all 0.15s ease-in-out;
  }
`
