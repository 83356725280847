import React, { useContext } from "react";
import { navigate } from "gatsby";
import styled from "styled-components";

import LockIcon from "../images/icons/lock-icon";

import CartContext from "../context/cart/cart-context";

import Coupons from "../components/checkout/coupons-panel";
import Drawer, { drawerSpacing } from "./drawer";

import { formatPrice } from "../lib/format";

const CartDrawer = ({ isCartPage = false, location }) => {
  const {
    cartDrawerOpen,
    openCartDrawer,
    removeFromCart,
    updateInCart,
    cartContents,
    cartMeta,
    cartReady,
  } = useContext(CartContext);

  return (
    <Drawer
      location={location}
      drawerType="Cart"
      subtitle="Have everything you need?"
      drawerOpen={cartDrawerOpen || isCartPage}
      isDrawerPage={isCartPage}
      drawerContents={cartContents}
      loading={!cartReady}
      setDrawerOpen={openCartDrawer}
      updateItem={updateInCart}
      removeItem={removeFromCart}
    >
      {!cartMeta ? null : (
        <>
          <Coupons />
          <TotalStyling drawerSpacing={drawerSpacing}>
            {cartMeta.discount > 0 && (
              <TotalsStyling>
                <h6>Discount</h6>
                <h6>{formatPrice(cartMeta.discount)}</h6>
              </TotalsStyling>
            )}
            <TotalsStyling>
              <h5>Grand Total</h5>
              <h5>{formatPrice(cartMeta.subtotal)}</h5>
            </TotalsStyling>
            <VatStyling>
              <em>Incl. VAT 15%</em>
              <em>{formatPrice(cartMeta.subtotal_tax)}</em>
            </VatStyling>
          </TotalStyling>
          <CheckoutButtonContainerStyling>
            <button
              disabled={!cartReady}
              onClick={() => {
                openCartDrawer(false);
                navigate("/checkout/");
              }}
            >
              Checkout
            </button>
          </CheckoutButtonContainerStyling>
          <SecureCheckoutStyling>
            <LockIcon />
            Secure Checkout
          </SecureCheckoutStyling>
        </>
      )}
    </Drawer>
  );
};

export default CartDrawer;
// ===============
//     STYLES
// ===============
const SecureCheckoutStyling = styled.div`
  display: flex;
  justify-content: center;
  background-color: white;
  align-items: center;
  padding-bottom: 2rem;
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    padding: 1rem;
  }
  svg {
    margin-right: 10px;
  }
`;
const TotalsStyling = styled.div`
  display: flex;
  justify-content: space-between;
`;
const VatStyling = styled.div`
  display: flex;
  justify-content: space-between;
  em {
    font-size: 0.75rem;
  }
`;
const TotalStyling = styled.div`
  padding: 0 ${({ drawerSpacing }) => drawerSpacing} 30px;
`;

const CheckoutButtonContainerStyling = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: auto;
  background-color: white;
  padding: 2rem 0;
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    padding: 2rem;
  }
  button {
    height: 100%;
  }
`;
