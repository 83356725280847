import React from "react"

const Twitter = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="23.139"
    viewBox="0 0 28.49 23.139"
  >
    <path
      id="Icon_awesome-twitter"
      data-name="Icon awesome-twitter"
      d="M25.561,9.147c.018.253.018.506.018.759,0,7.719-5.875,16.613-16.613,16.613A16.5,16.5,0,0,1,0,23.9a12.079,12.079,0,0,0,1.41.072,11.694,11.694,0,0,0,7.249-2.495A5.849,5.849,0,0,1,3.2,17.427a7.363,7.363,0,0,0,1.1.09,6.175,6.175,0,0,0,1.537-.2,5.84,5.84,0,0,1-4.682-5.73v-.072a5.88,5.88,0,0,0,2.639.741A5.848,5.848,0,0,1,1.988,4.447a16.6,16.6,0,0,0,12.039,6.11,6.591,6.591,0,0,1-.145-1.338,5.845,5.845,0,0,1,10.105-4,11.5,11.5,0,0,0,3.706-1.41,5.823,5.823,0,0,1-2.567,3.218,11.705,11.705,0,0,0,3.362-.9,12.551,12.551,0,0,1-2.928,3.019Z"
      transform="translate(0 -3.381)"
      fill={fill || "#003A8D"}
    />
  </svg>
)

export default Twitter
