import React from "react"
import styled from "styled-components"

import LinkWrapper from "../../lib/link-wrapper"

const SearchDropdown = ({
  title,
  searchResults,
  searchResultClicked,
  searchContainerFocus,
}) => {
  return (
    <>
      {searchResults.length > 0 && searchContainerFocus && (
        <>
          <TypeTitleStyling>{title}</TypeTitleStyling>
          {searchResults.map((hit) => {
            return !hit?.objectID ? null : (
              <StyledLinkWrapper
                id={`search-result-hit-${hit.objectID}`}
                key={hit.objectID}
                to={formatUrl(hit.slug, hit.type)}
                className={`${
                  searchResultClicked === `search-result-hit-${hit.objectID}`
                    ? `clicked`
                    : `not-clicked`
                } ${hit.type === "product" ? `product` : `not-product`}`}
              >
                {hit.type === "product" && hit.thumbnail && (
                  <ThumbnailImgStyling src={hit.thumbnail} alt={hit.title} />
                )}
                <HitTitleStyling>{`${hit.title}`}</HitTitleStyling>
              </StyledLinkWrapper>
            )
          })}
        </>
      )}
    </>
  )
}

export default SearchDropdown

// ==========
// 	 STYLES
// ==========
const StyledLinkWrapper = styled(LinkWrapper)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightgrey3};
  font-size: 1rem;
  &:hover {
    box-shadow: 0 0 25px 0px ${({ theme }) => theme.colors.black1}33;
    position: relative;
    z-index: 101;
  }
  &.clicked {
    background-color: ${({ theme }) => theme.colors.lightblue};
  }
  &.not-clicked {
    background-color: white;
  }
  &:first-child {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  &:last-child {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
`
const ThumbnailImgStyling = styled.img`
  width: 64px;
  height: 64px;
  object-fit: cover;
  margin: auto 0;
`
const HitTitleStyling = styled.div`
  color: ${({ theme }) => theme.colors.black1} !important;
  margin-left: 20px;
`
const TypeTitleStyling = styled.h3`
  color: ${({ theme }) => theme.colors.black1} !important;
`

// Format search result urls
const formatUrl = (slug, typeString) => {
  if (typeString == "product") {
    return `/product/${slug}/`
  } else if (typeString == "category") {
    return `/${slug}/`
  } else if (typeString == "page") {
    return `/${slug}/`
  } else if (typeString == "post") {
    return `/blog/${slug}/`
  }
}
