import React from "react"
import styled from "styled-components"

const Skeleton = styled.div`
  &#ellipsis-loader-skelleton {
    overflow: hidden;
    margin: ${(props) => props.margin};
    padding: ${(props) => props.padding};
    ${(props) => props.center && "text-align: center;"}
    height: ${(props) => props.height};
    .lds-ellipsis {
      display: inline-block;
      position: relative;
      width: 64px;
      height: 16px;
    }
    .lds-ellipsis div {
      position: absolute;
      top: 5px;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background: ${({ theme, colour }) => colour || theme.colors.blue};
      animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    .lds-ellipsis div:nth-child(1) {
      left: 6px;
      animation: lds-ellipsis1 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(2) {
      left: 6px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(3) {
      left: 26px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(4) {
      left: 45px;
      animation: lds-ellipsis3 0.6s infinite;
    }
    @keyframes lds-ellipsis1 {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }
    @keyframes lds-ellipsis3 {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(0);
      }
    }
    @keyframes lds-ellipsis2 {
      0% {
        transform: translate(0, 0);
      }
      100% {
        transform: translate(19px, 0);
      }
    }
  }
`

const CompactLoader = ({
  margin = "0",
  padding = "10px",
  height = "100%",
  colour = false,
  center = false,
}) => {
  return (
    <Skeleton
      id="ellipsis-loader-skelleton"
      padding={padding}
      margin={margin}
      height={height}
      colour={colour}
      center={center}
    >
      <div>
        <div className="lds-ellipsis">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    </Skeleton>
  )
}
export default CompactLoader
