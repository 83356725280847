import React from "react"
import styled from "styled-components"

import LinkWrapper from "../lib/link-wrapper"
import { getProperty } from "../lib/utils"

const Breadcrumb = ({ breadcrumb = [], location = {} }) => {
  const length = breadcrumb.length
  const locationPathname = location?.pathname?.endsWith("/")
    ? location?.pathname?.slice(0, -1)
    : location?.pathname
  let className = ""
  if (
    locationPathname == "/wishlist" ||
    locationPathname == "/cart" ||
    locationPathname == "/contact-us"
  ) {
    className = "hide-breadcrumb"
  }
  return (
    <BreadCrumbStyling className={className}>
      {breadcrumb.map((crumb, crumb_i) => {
        const pathname = getProperty(crumb, ["pathname"], "/")
        const pathnameFixed = pathname
          .split("/")
          .map((bread) => {
            if (bread === "product") return "shop"
            return bread
          })
          .join("/")
        const crumbLabel = getProperty(crumb, ["crumbLabel"], "")
        return (
          <StyledDiv key={crumb_i}>
            <LinkWrapper to={pathnameFixed}>
              <StyledH4 className="light">
                {crumbLabel.replace(/-/g, " ")}
              </StyledH4>
            </LinkWrapper>
            {length && crumb_i !== length - 1 && (
              <StyledH4Slash className="light">/</StyledH4Slash>
            )}
          </StyledDiv>
        )
      })}
    </BreadCrumbStyling>
  )
}

export default Breadcrumb

// ===============
//     STYLES
// ===============
const BreadCrumbStyling = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;

  > div:last-child > a > h4 {
    color: ${({ theme }) => theme.colors.brandHover};
  }
`
const StyledDiv = styled.div`
  display: inline;
`
const StyledH4 = styled.h4`
  text-transform: capitalize;
  display: inline;
  overflow-wrap: normal;
  color: ${({ theme }) => theme.colors.grey};
`
const StyledH4Slash = styled.h4`
  display: inline;
  padding: 0 10px;
`
