import React from "react"

function TikTok() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.49"
      height="32.793"
      viewBox="0 0 28.49 32.793"
    >
      <path
        fill="#03111f"
        d="M17.331.027C19.121 0 20.9.014 22.674 0a8.511 8.511 0 002.391 5.7 9.636 9.636 0 005.793 2.446v5.504a14.625 14.625 0 01-5.739-1.325 16.882 16.882 0 01-2.213-1.271c-.014 3.99.014 7.979-.027 11.956a10.436 10.436 0 01-1.845 5.383 10.18 10.18 0 01-8.075 4.386 9.963 9.963 0 01-5.575-1.407 10.3 10.3 0 01-4.987-7.8 25.642 25.642 0 01-.014-2.036 10.286 10.286 0 0111.928-9.127c.027 2.022-.055 4.044-.055 6.067a4.688 4.688 0 00-5.985 2.9 5.42 5.42 0 00-.191 2.2 4.65 4.65 0 004.782 3.921 4.59 4.59 0 003.785-2.2 3.153 3.153 0 00.56-1.448c.137-2.446.082-4.878.1-7.324.014-5.506-.014-11 .027-16.492z"
        data-name="Icon simple-tiktok"
        transform="translate(-2.369)"
      ></path>
    </svg>
  )
}

export default TikTok
