import React from "react"

const UserIcon = ({ stroke }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="21.25"
    viewBox="0 0 19 21.25"
  >
    <g id="User" transform="translate(0.5 0.5)">
      <path
        id="Path_78"
        data-name="Path 78"
        d="M6,29.25V27a4.5,4.5,0,0,1,4.5-4.5h9A4.5,4.5,0,0,1,24,27v2.25Z"
        transform="translate(-6 -9)"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Path_79"
        data-name="Path 79"
        d="M21,9a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,21,9Z"
        transform="translate(-7.5 -4.5)"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>
)

export default UserIcon
