import React from "react"
import styled from "styled-components"

import DrawerProductRow from "./drawer-product-row"

import Grid from "../components/generic/layout/grid"

import { roundTo2 } from "../lib/utils"
const drawerGridSpacingDesktop = "30% 54% 16%"
const drawerGridSpacingMobile = "30% 50% 30%"

const DrawerProductTable = ({
  products,
  loading = false,
  closeDrawer = () => {},
  removeItemFromDrawer = () => {},
  decrementItemInDrawer = () => {},
  incrementItemInDrawer = () => {},
  moveItemToCart = false,
  hideProductQuantity = false,
  drawerSpacing = "30px",
}) => {
  const productsMap = {}
  const productsToShow = []
  const productKeysShownInCart = []

  for (let index = 0; index < products.length; index++) {
    productsMap[products[index]?.key] = products[index]
  }

  // Loop through all of the products and only display addons under their parent
  for (let index = 0; index < products.length; index++) {
    const prod = products[index]
    // if not an addon
    if (!prod?.addon_base_product) {
      // if this product has addons
      if (prod?.add_ons?.length) {
        prod.add_on_products = prod?.add_ons?.forEach?.((key) =>
          productKeysShownInCart.push(key)
        )
      }
      productsToShow.push(prod)
      productKeysShownInCart.push(prod.key)
    }
  }

  // Loop through all of the products and make sure to display floating products
  for (let index = 0; index < products.length; index++) {
    const prod = products[index]
    // if this product is an addon
    if (!productKeysShownInCart.includes(prod?.key)) {
      productsToShow.push(prod)
    }
  }
  return (
    <ProductGridStyling>
      <StyledGridHead
        cols="3"
        spacing={drawerGridSpacingDesktop}
        drawerSpacing={drawerSpacing}
        drawerGridSpacingMobile={drawerGridSpacingMobile}
      >
        <div>Product</div>
        <div>Price</div>
        {hideProductQuantity ? <div /> : <div>Quantity</div>}
      </StyledGridHead>
      {productsToShow.map((item, item_i) => {
        const squares = parseFloat(item?.square_metres_of_tiles_per_box || 0)
        const area = isNaN(squares) ? 0 : roundTo2(item.quantity * squares)
        return (
          <span key={`span-${item_i}`}>
            <DrawerProductRow
              key={`outer-${item_i}`}
              loading={loading}
              drawerGridSpacingDesktop={drawerGridSpacingDesktop}
              drawerSpacing={drawerSpacing}
              drawerGridSpacingMobile={drawerGridSpacingMobile}
              removeItemFromDrawer={removeItemFromDrawer}
              item={item}
              closeDrawer={closeDrawer}
              squares={squares}
              area={area}
              moveItemToCart={moveItemToCart}
              hideProductQuantity={hideProductQuantity}
              decrementItemInDrawer={decrementItemInDrawer}
              incrementItemInDrawer={incrementItemInDrawer}
            />
            {!item?.add_ons?.length ? null : (
              <AddonSectionStyling>
                <AddonStyledHeading className={loading ? "blur" : ""}>
                  Supplimentary products for
                  <strong
                    dangerouslySetInnerHTML={{
                      __html: item.product_name,
                    }}
                  />
                </AddonStyledHeading>
                {item?.add_ons?.map?.((key, key_i) => {
                  const addon = productsMap?.[key]
                  if (!addon) {
                    return null
                  }
                  return (
                    <DrawerProductRow
                      key={`inner-${item_i}-${key_i}`}
                      loading={loading}
                      drawerGridSpacingDesktop={drawerGridSpacingDesktop}
                      drawerSpacing={drawerSpacing}
                      drawerGridSpacingMobile={drawerGridSpacingMobile}
                      removeItemFromDrawer={removeItemFromDrawer}
                      item={addon}
                      closeDrawer={closeDrawer}
                      moveItemToCart={moveItemToCart}
                      hideProductQuantity={hideProductQuantity}
                      decrementItemInDrawer={decrementItemInDrawer}
                      incrementItemInDrawer={incrementItemInDrawer}
                      bottomBorder={key_i < item.add_ons.length - 1}
                    />
                  )
                })}
              </AddonSectionStyling>
            )}
          </span>
        )
      })}
    </ProductGridStyling>
  )
}

export default DrawerProductTable

const AddonSectionStyling = styled.div`
  padding-top: 10px;
  margin: 0px 30px 0px 30px;
  background-color: ${({ theme }) => theme.colors.lightgrey3};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
`

const AddonStyledHeading = styled.span`
  margin: 0px 30px;
  strong {
    padding-left: 5px;
  }
  max-width: 100%;
  display: block;
  line-height: 1.5;
`

const ProductGridStyling = styled.div`
  .blur {
    filter: blur(3px);
  }
  margin-bottom: 40px;
`
const StyledGridHead = styled(Grid)`
  background-color: ${({ theme }) => theme.colors.brandPrimary};
  padding: 20px ${({ drawerSpacing }) => drawerSpacing};
  & > div {
    width: 100%;
    color: white;

    :first-child {
      text-align: center;
      padding-left: 0;
    }
    :nth-child(2) {
      text-align: right;
      padding-right: 30px;
      @media (max-width: 650px) {
        visibility: hidden;
      }
    }
    :last-child {
      text-align: center;
      padding-left: 0;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    grid-template-columns: ${({ drawerGridSpacingMobile }) =>
      drawerGridSpacingMobile};
  }
`
