import React from "react"

const LockIcon = ({ fill = "#4A4A4C" }) => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 13 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="icon" transform="translate(-6.000000, -5.000000)" fill={fill}>
        <path
          d="M12.1249854,16.4843985 C11.8242049,16.4843985 11.5781117,16.2383054 11.5781117,15.9375249 L11.5781117,14.187529 C11.5781117,13.8867485 11.8242049,13.6406553 12.1249854,13.6406553 C12.4257659,13.6406553 12.6718591,13.8867485 12.6718591,14.187529 L12.6718591,15.9375249 C12.6718591,16.2383054 12.4257659,16.4843985 12.1249854,16.4843985 Z M18.2499708,12.4375332 L18.2499708,17.6875207 C18.2499708,18.4121283 17.6620816,19.0000254 16.9374739,19.0000254 L7.31249687,19.0000254 C6.58788922,19.0000254 6,18.4121283 6,17.6875207 L6,12.4375332 C6,11.7129256 6.58788922,11.1250363 7.31249687,11.1250363 L7.74999583,11.1250363 L7.74999583,9.3750405 C7.74999583,6.95784808 9.71874113,4.99185317 12.1386572,5 C14.5558497,5.00824869 16.499975,6.99886361 16.499975,9.41605603 L16.499975,11.1250363 L16.9374739,11.1250363 C17.6620816,11.1250363 18.2499708,11.7129256 18.2499708,12.4375332 Z M8.62499374,11.1250363 L15.6249771,11.1250363 L15.6249771,9.37504884 C15.6249771,7.44458703 14.0554389,5.87504884 12.1249937,5.87504884 C10.1945319,5.87504884 8.62499374,7.44458703 8.62499374,9.37504884 L8.62499374,11.1250363 Z M17.3749729,17.6875207 L17.3749729,12.4375332 C17.3749729,12.1969141 17.178093,12.0000342 16.9374739,12.0000342 L7.31249687,12.0000342 C7.07187779,12.0000342 6.87499791,12.1969141 6.87499791,12.4375332 L6.87499791,17.6875207 C6.87499791,17.9281398 7.07187779,18.1250196 7.31249687,18.1250196 L16.9374739,18.1250196 C17.178093,18.1250196 17.3749729,17.9281398 17.3749729,17.6875207 Z"
          id=""
        ></path>
      </g>
    </g>
  </svg>
)

export default LockIcon
