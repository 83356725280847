import { useEffect } from "react"

export const setBodyScrollLockHook = (shouldLock) => {
  useEffect(() => {
    if (isBrowser()) shouldLock ? lockScrolling() : allowScrolling()
  }, [shouldLock])
}

// ======================
// 	      HELPERS
// ======================
const isBrowser = () => typeof window !== "undefined"
const lockScrolling = () => (document.body.style.overflow = "hidden")
const allowScrolling = () => (document.body.style.overflow = "")
