import React, { useState } from "react"
import styled from "styled-components"

import { isEmailValid, urlEncodeJsonObject } from "../../lib/utils"

import FormInputField from "../generic/form-input-field"

const SubscriptionForm = ({
  location,
  className = "", //optional
}) => {
  const formName = "subscription"
  const [submitError, setSubmitError] = useState("")
  const [emailValue, setEmailValue] = useState({ id: "", value: "" })
  const [submitMessage, setSubmitMessage] = useState("")

  const handleSubmit = (event) => {
    event.preventDefault()
    if (!isEmailValid(emailValue.value)) {
      setSubmitError("Invalid email")
      return
    }

    const dataObject = { "form-name": formName }
    dataObject[emailValue.id] = emailValue.value

    const bodyData = urlEncodeJsonObject(dataObject)

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: bodyData,
    })
      .then((response) => {
        setSubmitMessage("Thanks for subscribing to our newsletter")
        setSubmitError("")
      })
      .catch((err) => {
        console.error("Subscription failed", err)
        setSubmitError("Subscription failed")
        setSubmitMessage("")
      })
  }

  return (
    <FormStyling className={className}>
      <SubscriptionFormSectionStyling>
        {submitMessage ? (
          <FormSubmitMessageStyling>{submitMessage}</FormSubmitMessageStyling>
        ) : (
          <form
            method="post"
            netlify-honeypot="bot-field"
            data-netlify="true"
            name={formName}
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value={formName} />
            <FormInputField
              fieldType="email"
              placeholder="Enter your email address"
              fieldId={`${formName}-email`}
              inputValue={emailValue.value}
              onValueChange={(value) => {
                setEmailValue(value)
              }}
            />

            {submitError && (
              <FormSubmitErrorMessageStyling style={{ color: "red" }}>
                {submitError}
              </FormSubmitErrorMessageStyling>
            )}
            <StyledSubmitButton type="submit">Subscribe</StyledSubmitButton>
          </form>
        )}
      </SubscriptionFormSectionStyling>
    </FormStyling>
  )
}

export default SubscriptionForm

// ===============
//     STYLES
// ===============
const FormStyling = styled.div`
  margin: 0 auto;
`
const SubscriptionFormSectionStyling = styled.div`
  label {
  }
  input {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
    outline: none;
    width: 100%;
    color: ${({ theme }) => theme.colors.grey};
    margin: 2rem 0;
    &::placeholder {
      font-weight: 300;
    }
    @media (max-width: ${({ theme }) => theme.breakLarge}) {
      margin: 0 0 1.5rem;
    }
  }
`
const FormSubmitMessageStyling = styled.div`
  padding: 25px 0;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.grey};
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    text-align: center;
  }
`

const FormSubmitErrorMessageStyling = styled.div`
  padding-top: 10px;
  font-size: 1rem;
  width: 100%;
`

const StyledSubmitButton = styled.button`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.grey};
  background-color: ${({ theme }) => theme.colors.lightgrey3};
  color: ${({ theme }) => theme.colors.grey};
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey};
    color: ${({ theme }) => theme.colors.white};
  }
`
