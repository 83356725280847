import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"

const SEO = ({ title, description, lang, meta = {}, yoast = {} }) => {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={yoast?.title || title}
      meta={[
        {
          name: `description`,
          content: yoast?.description || description,
        },
        {
          property: `og:type`,
          content: yoast?.og_type || `website`,
        },
        {
          property: `og:title`,
          content: yoast?.og_title || title,
        },
        {
          property: `og:description`,
          content: yoast?.og_description || yoast?.description,
        },
        {
          property: `og:site_name`,
          content: yoast?.site_name || "",
        },
        {
          property: `og:image`,
          content: yoast?.og_image || "",
        },
        {
          name: `twitter:card`,
          content: yoast?.twitter_card || `summary`,
        },
        {
          name: `twitter:creator`,
          content: yoast?.twitter_creator || "",
        },
        {
          name: `twitter:title`,
          content: yoast?.twitter_title || title,
        },
        {
          name: `twitter:description`,
          content: yoast?.twitter_description || yoast?.description,
        },
      ].concat(meta)}
    ></Helmet>
  )
}

export default SEO
