import React from "react"

function Pinterest() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="35.094"
      viewBox="0 0 27 35.094"
    >
      <path
        fill="#03111f"
        d="M14.344.457C7.13.457 0 5.266 0 13.05c0 4.95 2.784 7.763 4.472 7.763.7 0 1.1-1.941 1.1-2.489 0-.654-1.666-2.046-1.666-4.767A9.5 9.5 0 0113.774 3.9c4.788 0 8.332 2.721 8.332 7.72 0 3.734-1.5 10.737-6.349 10.737a3.122 3.122 0 01-3.248-3.08c0-2.658 1.856-5.231 1.856-7.973 0-4.655-6.6-3.811-6.6 1.814a8.26 8.26 0 00.675 3.565c-.97 4.177-2.953 10.4-2.953 14.7 0 1.329.19 2.637.316 3.966.239.267.12.239.485.105C9.83 30.6 9.7 29.651 11.306 23.3c.865 1.645 3.1 2.531 4.873 2.531C23.646 25.833 27 18.555 27 12 27 5.013 20.967.457 14.344.457z"
        data-name="Icon awesome-pinterest-p"
        transform="translate(0 -.457)"
      ></path>
    </svg>
  )
}

export default Pinterest
