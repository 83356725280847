import React, { useContext, useState } from "react"
import styled from "styled-components"

import CartContext from "../context/cart/cart-context"
import ToastNotification from "../theme/toast-notification"

import Drawer from "./drawer"

const WishlistDrawer = ({ isWishlistPage = false, location }) => {
  const {
    wishlistDrawerOpen,
    openWishlistDrawer,
    removeFromWishlist,
    wishlistContents,
    cartReady,
    setCartReady,
    addToCart,
    openCartDrawer,
  } = useContext(CartContext)
  const [failedProductMessage, setFailedProductMessage] = useState(false)

  // Move a single wishlist item to cart
  const moveItemToCart = async (id) => {
    setCartReady(false)
    setFailedProductMessage(false)
    await removeFromWishlist(id)
    openWishlistDrawer(false)
    try {
      await addToCart({ id })
    } catch (error) {
      let errorString = "Failed to add product to cart"
      if (error.message) {
        errorString = error.message
      }
      // setFailedProductMessage(`Cannot add ${items[i].name} to cart`)
      setFailedProductMessage(errorString)
      setCartReady(true)
    }
    openCartDrawer(true)
  }

  // Move all wishlist items to cart
  const moveAllItemsToCart = async () => {
    setFailedProductMessage(false)
    const ids = []
    for (let i = 0; i < wishlistContents.length; i++) {
      ids.push(wishlistContents[i].product_id)
      await removeFromWishlist(wishlistContents[i].product_id)
    }
    openWishlistDrawer(false)
    for (let i = 0; i < ids.length; i++) {
      try {
        await addToCart({ id: ids[i] })
      } catch (error) {
        let errorString = "Failed to add product to cart"
        if (error.message) {
          errorString = error.message
        }
        // setFailedProductMessage(`Cannot add ${items[i].name} to cart`)
        setFailedProductMessage(errorString)
      }
    }
    openCartDrawer(true)
  }

  return (
    <>
      {failedProductMessage && (
        <ToastNotification location={location} message={failedProductMessage} />
      )}
      <Drawer
        location={location}
        drawerType="Wishlist"
        drawerOpen={wishlistDrawerOpen || isWishlistPage}
        isDrawerPage={isWishlistPage}
        drawerContents={wishlistContents}
        loading={!cartReady}
        setDrawerOpen={() => openWishlistDrawer(false)}
        removeItem={(prod) => removeFromWishlist(prod.product_id)}
        moveItemToCart={moveItemToCart}
        moveAllItemsToCart={moveAllItemsToCart}
        hideProductQuantity
      >
        <CheckoutButtonContainerStyling>
          <button className="moveButton" onClick={moveAllItemsToCart}>
            Move all to cart
          </button>
        </CheckoutButtonContainerStyling>
      </Drawer>
    </>
  )
}

export default WishlistDrawer

const CheckoutButtonContainerStyling = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: auto;
  background-color: white;
  padding: 1rem 0;
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    padding: 1rem;
  }
  button {
    height: 100%;
  }
`
