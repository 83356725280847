import React, { useEffect, useState } from "react";
import styled from "styled-components";

const ToastNotification = ({ location, message = "" }) => {
  const [noticeActive, setNoticeActive] = useState(false);
  const [noticeContent, setNoticeContent] = useState("");

  // Trigger a 3 second timeout, then the notice disappears
  const triggerNoticeTimeout = () => {
    setTimeout(() => {
      setNoticeActive(false);
      setNoticeContent("");
    }, 3000);
  };

  // On message change
  useEffect(() => {
    if (message) {
      setNoticeContent(message);
      setNoticeActive(true);
    }
    triggerNoticeTimeout()
  }, [message]);

  // On location (url) change
  useEffect(() => {
    const showNotice =
      (location && location.state && location.state.showNotice) || message;
    if (showNotice) {
      setNoticeContent(showNotice);
      setNoticeActive(true);
    }
    triggerNoticeTimeout();
  }, [location]);

  return (
    <NotificationStyling className={`toaststyle ${noticeActive ? "show" : ""}`}>
      {noticeContent}
    </NotificationStyling>
  );
};

export default ToastNotification;

// ===============
//     STYLES
// ===============
const NotificationStyling = styled.div`
  &.toaststyle {
    display: none;
    visibility: hidden;
    position: fixed;
    top: 250px;
    left: 50%;
    z-index: 250;
    min-width: 250px;
    padding: 20px;
    border-radius: 40px;
    box-shadow: 0 0 25px ${({ theme }) => theme.colors.darkgrey};
    background-color: ${({ theme }) => theme.colors.brandPrimaryLight};
    color: ${({ theme }) => theme.colors.white};
    text-align: center;
    width: 700px;
    margin-left: -350px;
    line-height: 1.5;
    font-size: 1.1rem;
    @media (max-width: ${({ theme }) => theme.breakMedium}) {
      width: 360px;
      margin-left: -180px;
      font-size: 1rem;
    }
    @media (max-width: ${({ theme }) => theme.breakMedium}) {
      top: 100px;
    }

    // The animation code time should total the timeout value
    &.show {
      display: block;
      visibility: visible;
      -webkit-animation: fadein 0.5s, fadeout 0.5s 4.5s;
      animation: fadein 0.5s, fadeout 0.5s 4.5s;
    }

    /* Animations to fade the message in and out */
    @-webkit-keyframes fadein {
      from {
        top: 0;
        opacity: 0;
      }
      to {
        top: 250px;
        opacity: 1;
      }
    }
    @keyframes fadein {
      from {
        top: 0;
        opacity: 0;
      }
      to {
        top: 250px;
        opacity: 1;
      }
    }
    @-webkit-keyframes fadeout {
      from {
        top: 250px;
        opacity: 1;
      }
      to {
        top: 0;
        opacity: 0;
      }
    }
    @keyframes fadeout {
      from {
        top: 250px;
        opacity: 1;
      }
      to {
        top: 0;
        opacity: 0;
      }
    }

    @media (max-width: ${({ theme }) => theme.breakMedium}) {
      @-webkit-keyframes fadein {
        from {
          top: 0;
          opacity: 0;
        }
        to {
          top: 100px;
          opacity: 1;
        }
      }
      @keyframes fadein {
        from {
          top: 0;
          opacity: 0;
        }
        to {
          top: 100px;
          opacity: 1;
        }
      }
      @-webkit-keyframes fadeout {
        from {
          top: 100px;
          opacity: 1;
        }
        to {
          top: 0;
          opacity: 0;
        }
      }
      @keyframes fadeout {
        from {
          top: 100px;
          opacity: 1;
        }
        to {
          top: 0;
          opacity: 0;
        }
      }
    }
  }
`;
