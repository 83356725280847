import React from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import { useEffect, useState } from "react"
import { search } from "sea-search"

import searchIcon from "../../images/icons/search.svg"

const SearchInput = ({
  className = "",
  searchPlaceholderText,
  query,
  setLoading = () => {},
  callback = () => {},
  enterKeySearch = true,
}) => {
  // Search text field
  const [text, setText] = useState(query)
  const [results, setResults] = useState([])

  // When search results change, send to the callback function
  useEffect(() => {
    if (typeof text === "undefined" || text.length === 0 || !text) {
      setResults([])
      return
    }

    setLoading(true)
    search({
      url: "https://ki6cu8re6g.execute-api.af-south-1.amazonaws.com/default",
      query: text,
      numResults: 6,
    })
      .then((resp) => {
        setResults(resp)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setResults([])
        setLoading(false)
      })
  }, [text])

  // When search results change, send to the callback function
  useEffect(() => {
    callback(results, text)
  }, [results])

  return (
    <SearchInputStyling
      className={className}
      type="text"
      placeholder={searchPlaceholderText}
      defaultValue={query ? query : undefined}
      onKeyPress={(e) => {
        if (e.key === "Enter" && enterKeySearch) {
          if (
            typeof window != "undefined" &&
            window.location.pathname.includes("search")
          ) {
            window.location = `${window.location.origin}${window.location.pathname}?query=${e.target.value}`
          } else {
            navigate(`/search/?query=${e.target.value}`)
          }
        }
      }}
      onChange={(e) => {
        setText(e.target.value)
      }}
    />
  )
}

export default SearchInput

export const filterSearchResults = (response) => {
  if (response && response?.length) {
    const filteredProducts = response.filter((prodHits) => {
      return prodHits.type === "product"
    })

    const filteredPosts = response.filter((postHit) => {
      return postHit.type === "post"
    })

    const filteredPages = response.filter((pageHit) => {
      return pageHit.type === "page"
    })

    const filteredCategories = response.filter((pageHit) => {
      return pageHit.type === "category"
    })

    return {
      products: filteredProducts,
      pages: filteredPages,
      posts: filteredPosts,
      categories: filteredCategories,
    }
  }
  // hide all results when string goes empty
  return {
    products: [],
    pages: [],
    posts: [],
    categories: [],
  }
}

// ==========
// 	 STYLES
// ==========
const SearchInputStyling = styled.input`
  padding: 10px 20px;
  border: none;
  width: 100%;
  border-radius: 100px;
  background-color: white;
  color: ${({ theme }) => theme.colors.black1};
  font-size: 1rem;
  background-image: url(${searchIcon});
  background-repeat: no-repeat;
  background-position: 97% center;
  outline: none;
  -webkit-text-fill-color: ${({ theme }) => theme.colors.black1};
  &::placeholder {
    color: ${({ theme }) => theme.colors.black1};
  }
  box-shadow: none;
`
