import React, { useContext, memo } from "react"
import styled from "styled-components"
import LinkWrapper from "../../lib/link-wrapper"

import CartContext from "../../context/cart/cart-context"

import WishlistIcon from "../../images/icons/wishlist"

// ======================
// 	     COMPONENT
// ======================
const WishlistButton = memo(({ className = "" }) => {
  const { wishlistContents, openWishlistDrawer } = useContext(CartContext)

  return (
    <WishlistStyledLinkWrapper
      className={className}
      onClick={(e) => {
        openWishlistDrawer(true)
        e.preventDefault() // Do not perform default "LinkWrapper" navigate
      }}
    >
      <WishlistStyling className="cart-icon">
        {wishlistContents && wishlistContents.length > 0 && (
          <span className="notify">{wishlistContents.length}</span>
        )}
        <WishlistIcon />
      </WishlistStyling>
      <span className="cart-text">My Wishlist</span>
    </WishlistStyledLinkWrapper>
  )
})

export default WishlistButton

// ===============
//      STYLES
// ===============
const WishlistStyling = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  & span {
    text-align: center;
    line-height: 1rem;
    position: absolute;
    width: 100%;
    font-size: 0.8rem;
  }
  svg {
    margin: 0 5px 0 5px;
  }
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    margin: 0 12px 1px 4px;
  }
`
const WishlistStyledLinkWrapper = styled(LinkWrapper)`
  display: flex;
  align-items: center;
  svg {
    margin: 1px 10px 0 9px;
    padding-right: 1px;
  }
  :hover svg g path {
    fill: white;
    transition: all 0.15s ease-in-out;
  }
  svg g path {
    fill: transparent;
    transition: all 0.15s ease-in-out;
  }
  &:hover span:not(.cart-text) {
    color: ${({ theme }) => theme.colors.blue};
    transition: all 0.15s ease-in-out;
  }
  & span {
    color: white;
    transition: all 0.15s ease-in-out;
  }
  & .cart-text {
    margin-right: 30px;
  }
`
