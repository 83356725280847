import React from "react"

const Phone = ({ stroke, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 33.997 34.06"
  >
    <path
      id="Icon_feather-phone"
      data-name="Icon feather-phone"
      d="M35.161,27v4.826a3.217,3.217,0,0,1-3.507,3.217,31.836,31.836,0,0,1-13.883-4.939A31.369,31.369,0,0,1,8.12,20.454,31.835,31.835,0,0,1,3.181,6.507,3.217,3.217,0,0,1,6.382,3h4.826a3.217,3.217,0,0,1,3.217,2.767,20.655,20.655,0,0,0,1.126,4.52,3.217,3.217,0,0,1-.724,3.394l-2.043,2.043a25.739,25.739,0,0,0,9.652,9.652l2.043-2.043a3.217,3.217,0,0,1,3.394-.724,20.656,20.656,0,0,0,4.52,1.126A3.217,3.217,0,0,1,35.161,27Z"
      transform="translate(-2.166 -2)"
      fill="none"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
)

export default Phone
