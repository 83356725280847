import React from "react"
import styled from "styled-components"

import paygateLogos from "../../images/paygate-brand-logos.png"

const PaymentOptionLogos = () => {
  return (
    <PaymentOptionLogosStyling>
      <img src={paygateLogos} alt="paygateLogos" />
    </PaymentOptionLogosStyling>
  )
}

export default PaymentOptionLogos

// ===============
//      STYLES
// ===============
const PaymentOptionLogosStyling = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    display: flex;
    align-items: center;
    margin-right: 1.5rem;
  }
  span {
    margin-left: 10px;
  }
  img {
    margin-bottom: 0;
    width: 89%;
  }
`
