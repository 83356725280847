import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import MailIcon from "../../images/icons/mail"
import PhoneIcon from "../../images/icons/phone"

const FooterContactInformation = () => {
  const menuQueryData = useStaticQuery(MENU_QUERY)
  const menuItems = menuQueryData?.seaSiteMenuItems?.footer_contact_info

  return (
    <ContactInfoDetailsStyling>
      {menuItems.map((item, idx) => {
        // Get the corresponding Contact Info method icon
        const ContactInfoIcon = item.url.includes("mail") ? MailIcon : PhoneIcon
        // Check if the menu item url and label exists
        if (item.url != "#" && item.title) {
          return (
            <a href={item.url} key={idx}>
              <ContactInfoIcon stroke={"white"} width={"18"} height={"17"} />
              {item.title}
            </a>
          )
        } else if (item.title && item.url == "#") {
          // Check if the menu item label exists and the url is #
          return <div key={`${itemIndex}-contact-info-text`}>{item.label}</div>
        } else {
          return null
        }
      })}
    </ContactInfoDetailsStyling>
  )
}
export default FooterContactInformation

// ===============
//     STYLES
// ===============
const ContactInfoDetailsStyling = styled.div`
  color: white;
  a {
    width: fit-content;
    color: white;
    display: flex;
    align-items: center;
    line-height: 1;
    padding-top: 1rem;
    text-transform: none;
    svg {
      margin-right: 1rem;
    }
  }
`

// ===============
//      QUERY
// ===============

const MENU_QUERY = graphql`
  {
    seaSiteMenuItems {
      footer_contact_info {
        title
        url
      }
    }
  }
`
