import React from "react"
import styled from "styled-components"

import LinkWrapper from "../lib/link-wrapper"
import Grid from "../components/generic/layout/grid"

import { formatPrice } from "../lib/format"

const DrawerProductRow = ({
  loading,
  drawerGridSpacingDesktop,
  drawerSpacing,
  drawerGridSpacingMobile,
  removeItemFromDrawer,
  item,
  closeDrawer,
  moveItemToCart,
  hideProductQuantity,
  decrementItemInDrawer,
  incrementItemInDrawer,
  bottomBorder = true,
}) => {
  const slug = item.parent_slug || item.product_slug
  return (
    <ProductGridRowStyling
      className={loading ? "blur" : ""}
      spacing={drawerGridSpacingDesktop}
      drawerSpacing={drawerSpacing}
      drawerGridSpacingMobile={drawerGridSpacingMobile}
      bottomBorder={bottomBorder}
    >
      <ProductImgStyling>
        <StyledRemoveSubmitButton
          disabled={loading}
          onClick={() => removeItemFromDrawer(item)}
        >
          &#9587;
        </StyledRemoveSubmitButton>
        <img src={item.product_image} />
      </ProductImgStyling>
      <ProductNamePriceStyling>
        <ProductNameStyling
          to={slug && `/product/${slug}/`}
          onClick={closeDrawer}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: item.product_name,
            }}
          />
          {/* <ShippingStyling>
            Shipping Estimate:
            <ShippingNumberStyling>
              2-3 Working Days (In economic centers)
            </ShippingNumberStyling>
          </ShippingStyling> */}
        </ProductNameStyling>
        <ProductPriceStyling key="price">
          {formatPrice(item.price_total)}
        </ProductPriceStyling>
      </ProductNamePriceStyling>
      {hideProductQuantity ? null : (
        <QuantityStyling>
          <StyledQuantityButton
            disabled={loading}
            onClick={() => decrementItemInDrawer(item)}
          >
            -
          </StyledQuantityButton>
          {item.quantity}
          <StyledQuantityButton
            disabled={loading}
            onClick={() => incrementItemInDrawer(item)}
          >
            +
          </StyledQuantityButton>
        </QuantityStyling>
      )}
      {!moveItemToCart ? null : (
        <button
          className="moveButton"
          onClick={() => moveItemToCart(item.product_id)}
        >
          Move to cart
        </button>
      )}
    </ProductGridRowStyling>
  )
}

export default DrawerProductRow

const StyledRemoveSubmitButton = styled.button`
  padding: 0;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  width: 20px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.lightgrey4};
  font-size: 0.4rem;
  line-height: 0.4rem;
`

const StyledQuantityButton = styled.button`
  padding: 10px 2px;
  width: 30px;
  border-color: transparent;
  border-radius: 0;
  color: ${({ theme }) => theme.colors.black1};
  background-color: ${({ theme }) => theme.colors.white};
  &:nth-of-type(2) {
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
  }
  &:nth-of-type(1) {
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
  }
`

const ProductImgStyling = styled.div`
  max-width: 150px;
  width: 100%;
  display: flex;
  align-items: center;

  img {
    margin: 0;
    padding: 0 15px;
    object-fit: scale-down;
    overflow: hidden;
  }
  @media (max-width: 650px) {
    img {
      padding: 0 10px;
    }
  }
`

const QuantityStyling = styled.div`
  display: flex;
  background-color: white;
  width: 100%;
  max-width: 110px;
  border: 1px solid ${({ theme }) => theme.colors.brandPrimary};
  border-radius: 3px;
  justify-content: space-between;
  align-items: center;
`
const ProductNamePriceStyling = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 650px) {
    flex-wrap: wrap;
    & > * {
      font-size: 0.75rem;
    }
  }
`
const ProductNameStyling = styled(LinkWrapper)`
  padding: 0 10px;
  line-height: 1.2;
  font-weight: 600;
`
const ShippingStyling = styled.span`
  line-height: 1.2;
  font-weight: 300;
  font-size: 13px;
  margin-top: 0.75rem;
  display: block;
`
const ShippingNumberStyling = styled.span`
  margin-left: 0.5rem;
  font-weight: bold;
`
const ProductPriceStyling = styled.span`
  white-space: nowrap;
  padding-right: 10px;
  font-weight: 600;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    padding: 10px;
  }
`

const ProductGridRowStyling = styled(Grid)`
  margin: 0 ${({ drawerSpacing }) => drawerSpacing};
  padding: 20px 0;
  ${({ bottomBorder, theme }) =>
    !bottomBorder ? "" : `border-bottom: 1px solid ${theme.colors.grey};`}
  .moveButton {
    padding: 10px;
    font-size: 0.8em;
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      font-size: 0.75rem;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    grid-template-columns: ${({ drawerGridSpacingMobile }) =>
      drawerGridSpacingMobile};
  }
`
