import React from "react"
import styled from "styled-components"
import CloudflareImage from "../reusable/cloudflare-image"

const Banner = ({ details = {} }) => {
  return (
    <Container>
      <CloudflareImage
        alt="Banner Image"
        className="banner-image"
        src={details.image}
        objectFit="cover"
      ></CloudflareImage>
      {details.theme === "dark" && <GradientOverlay></GradientOverlay>}
      <Inner className={details.theme}>
        <h1>{details.title}</h1>
        <h2 className="light">{details.subTitle}</h2>
        <Description className="large">{details.description}</Description>
        <Italic className="large">{details.italic}</Italic>
      </Inner>
    </Container>
  )
}

export default Banner

// ====================
//        STYLES
// ====================

const Container = styled.div`
  width: 100%;
  height: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;

  & > .banner-image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
  }

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    height: calc(100vh - 72px);
    margin-top: 72px;
  }
`
const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  background: transparent linear-gradient(180deg, #03111f00 0%, #03111f 100%) 0%
    0% no-repeat padding-box;
`

const Inner = styled.div`
  width: ${({ theme }) => theme.maxWidth};
  max-width: ${({ theme }) => theme.maxWidth};
  z-index: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;

  &.dark {
    color: white;
  }
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    max-width: ${({ theme }) => theme.breakMedium};
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    max-width: 100%;
    padding: 0 30px;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    max-width: 100%;
    padding: 0 25px;
  }
`
const Description = styled.p`
  max-width: 550px;
`

const Italic = styled.p`
  margin-top: 20px;
  font-style: italic;
`
