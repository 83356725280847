import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import LinkWrapper from "../../lib/link-wrapper"
import SearchBar from "../search/search-bar"
import CartButton from "./cart-button"
import WishlistButton from "./wishlist-button"
import AccountLinks from "./account-links"

const Header = ({ logo, wishlistEnabled }) => {
  const menuQueryData = useStaticQuery(MENU_QUERY)
  const menuItems = menuQueryData?.seaSiteMenuItems?.skinny_header_menu || []

  return (
    <HeaderStylingWrapper>
      <HeaderTopRow>
        <SkinnyHeaderStylingWrapper>
          {menuItems.map((item, linkIndex) => (
            <LinkWrapper
              key={linkIndex}
              to={item.url}
              className={item.cssClasses ? item.cssClasses : ""}
            >
              {item.title}
            </LinkWrapper>
          ))}
        </SkinnyHeaderStylingWrapper>
        <DesktopAccountLinksLayout>
          <AccountLinks />
        </DesktopAccountLinksLayout>
      </HeaderTopRow>
      <HeaderMiddleRow>
        <StyledLinkedLogo to="/">
          <img src={logo} />
        </StyledLinkedLogo>
        <SearchBar />
        <div>
          {wishlistEnabled && <WishlistButton />}
          <CartButton />
        </div>
      </HeaderMiddleRow>
    </HeaderStylingWrapper>
  )
}

export default Header

// ===============
//      STYLES
// ===============

const DesktopAccountLinksLayout = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 30%;
`
const SkinnyHeaderStylingWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  & .first-link {
    padding-left: 7%;
  }
  & a {
    padding: 0 3.5%;
  }

  & .not-a-link {
    cursor: default;
    padding: 0;
  }
`

const HeaderStylingWrapper = styled.header`
  background: ${({ theme }) => theme.colors.brandPrimary};
  text-transform: uppercase;
  a,
  div {
    color: white;
  }
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    display: none;
  }
`

const HeaderTopRow = styled.div`
  background: ${({ theme }) => theme.colors.brandPrimaryLight};
  font-size: 0.85rem;
  font-weight: 300;
  padding: 8px calc(calc(100% / 2) - calc(${({ theme }) => theme.maxWidth} / 2));
  border-bottom: 1px solid ${({ theme }) => theme.colors.brandSecondary};
  display: flex;
`

const HeaderMiddleRow = styled.div`
  padding: 20px
    calc(calc(100% / 2) - calc(${({ theme }) => theme.maxWidth} / 2));
  border-bottom: 1px solid ${({ theme }) => theme.colors.brandSecondary};
  display: flex;
  align-items: center;
  & > a {
    width: 18%;
    margin-right: 15%;
    min-height: 80px;
    display: flex;
    align-items: center;
  }
  & > div:first-of-type {
    width: 44%;
  }
  & > div:last-of-type {
    width: 24%;
    display: flex;
    justify-content: flex-end;
    font-weight: 300;
    font-size: 0.85rem;
  }
`

const StyledLinkedLogo = styled(LinkWrapper)`
  svg {
    margin: 0;
    height: auto;
  }
`

// ===============
//      QUERY
// ===============
const MENU_QUERY = graphql`
  {
    seaSiteMenuItems {
      skinny_header_menu {
        url
        title
      }
    }
  }
`
