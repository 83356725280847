import React from "react"

const Cart = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32.429"
    height="31"
    viewBox="0 0 32.429 31"
  >
    <g id="Cart_2" data-name="Cart 2" transform="translate(0.5 0.5)">
      <path
        id="Path_4"
        data-name="Path 4"
        d="M14.857,31.429A1.429,1.429,0,1,1,13.429,30,1.429,1.429,0,0,1,14.857,31.429Z"
        transform="translate(-2 -2.857)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Path_5"
        data-name="Path 5"
        d="M31.357,31.429A1.429,1.429,0,1,1,29.929,30,1.429,1.429,0,0,1,31.357,31.429Z"
        transform="translate(-2.786 -2.857)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Path_6"
        data-name="Path 6"
        d="M1.5,1.5H7.214l3.829,19.129a2.857,2.857,0,0,0,2.857,2.3H27.786a2.857,2.857,0,0,0,2.857-2.3L32.929,8.643H8.643L7.214,1.5Z"
        transform="translate(-1.5 -1.5)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>
)
export default Cart
