import React from "react"

const Linkedin = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="24.928"
    viewBox="0 0 24.928 24.928"
  >
    <path
      id="Icon_awesome-linkedin-in"
      data-name="Icon awesome-linkedin-in"
      d="M5.58,24.929H.412V8.286H5.58ZM2.993,6.015A3.007,3.007,0,1,1,5.986,2.994,3.018,3.018,0,0,1,2.993,6.015Zm21.93,18.913H19.766v-8.1c0-1.931-.039-4.407-2.687-4.407-2.687,0-3.1,2.1-3.1,4.268v8.241H8.817V8.286h4.957v2.27h.072a5.431,5.431,0,0,1,4.89-2.688c5.231,0,6.192,3.444,6.192,7.918v9.142Z"
      transform="translate(0 0)"
      fill={fill || "#003A8D"}
    />
  </svg>
)

export default Linkedin
