import React from "react"

const Facebook = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="28.49"
    viewBox="0 0 15.259 28.49"
  >
    <path
      id="Icon_awesome-facebook-f"
      data-name="Icon awesome-facebook-f"
      d="M15.868,16.025l.791-5.156H11.712V7.524a2.578,2.578,0,0,1,2.907-2.786h2.249V.348A27.428,27.428,0,0,0,12.876,0C8.8,0,6.138,2.469,6.138,6.94v3.93H1.609v5.156H6.138V28.49h5.574V16.025Z"
      transform="translate(-1.609)"
      fill={fill || "#003A8D"}
    />
  </svg>
)

export default Facebook
