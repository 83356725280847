import React from "react"

const Mail = ({ stroke, width = "30", height = "19" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 34.959 27.738"
  >
    <g
      id="Icon_feather-mail"
      data-name="Icon feather-mail"
      transform="translate(1.393 1)"
    >
      <path
        id="Path_80"
        data-name="Path 80"
        d="M6.217,6H31.956a3.227,3.227,0,0,1,3.217,3.217v19.3a3.227,3.227,0,0,1-3.217,3.217H6.217A3.227,3.227,0,0,1,3,28.521V9.217A3.227,3.227,0,0,1,6.217,6Z"
        transform="translate(-3 -6)"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Path_81"
        data-name="Path 81"
        d="M35.174,9,19.087,20.261,3,9"
        transform="translate(-3 -5.783)"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
)

export default Mail
