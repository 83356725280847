import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const Grid = ({
  cols = "1",
  spacing = "",
  gutter = "0",
  align = "center",
  justify = "flex-start",
  padding = "0 20px",
  children,
  className = "",
}) => {
  const columns = spacing ? spacing : `repeat(${cols}, 1fr)`
  const classNameplaceHolder = spacing
    ? `${className} grid grid-custom-spacing`
    : `${className} grid grid-cols-${cols} `

  return (
    <GridWrapper
      columns={columns}
      gutter={gutter}
      className={classNameplaceHolder}
      align={align}
      justify={justify}
      padding={padding}
    >
      {children}
    </GridWrapper>
  )
}

Grid.propTypes = {
  cols: PropTypes.any,
  spacing: PropTypes.string,
  gutter: PropTypes.string,
  align: PropTypes.string,
  justify: PropTypes.string,
}

export default Grid

// ===============
//     STYLES
// ===============
const GridWrapper = styled.div`
  display: grid;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0;
  grid-template-columns: ${(props) => props.columns};
  grid-gap: ${(props) => props.gutter};
  align-items: ${({ align }) => align};
  justify-items: ${({ justify }) => justify};
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    grid-template-columns: 1fr;
  }
`
