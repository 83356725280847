import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import FacebookIcon from "../../images/icons/facebook"
import InstagramIcon from "../../images/icons/instagram"
import LinkedinIcon from "../../images/icons/linkedin"
import TwitterIcon from "../../images/icons/twitter"
import PinterestIcon from "../../images/icons/pinterest"
import YoutubeIcon from "../../images/icons/youtube"
import TikTokIcon from "../../images/icons/tiktok"
import WhatsAppIcon from "../../images/icons/whatsapp"
import TelegramIcon from "../../images/icons/telegram"

const socialMediaIcons = {
  Facebook: FacebookIcon,
  Twitter: TwitterIcon,
  Instagram: InstagramIcon,
  LinkedIn: LinkedinIcon,
  Pinterest: PinterestIcon,
  Youtube: YoutubeIcon,
  TikTok: TikTokIcon,
  WhatsApp: WhatsAppIcon,
  Telegram: TelegramIcon,
}

const FooterSocialMedia = () => {
  const socialMediaQueryData = useStaticQuery(SOCIAL_MEDIA_QUERY)
  const socialMediaItems =
    socialMediaQueryData?.seaSiteInfo?.social_media?.social_media_platforms ||
    []

  const itemsToDisplay = socialMediaItems.filter(
    (item) => socialMediaIcons[item.platform_name]
  )

  return (
    <SocialMediaColumnStyling
      className={itemsToDisplay.length > 6 ? "small" : ""}
    >
      {itemsToDisplay.map((item, idx) => {
        // Get the corresponding socialMedia platforms icon
        const SocialIcon = socialMediaIcons[item.platform_name]
        return (
          <a key={idx} href={item.url}>
            <SocialIcon fill={"black"} />
          </a>
        )
      })}
    </SocialMediaColumnStyling>
  )
}
export default FooterSocialMedia

// ===============
//     STYLES
// ===============
const SocialMediaColumnStyling = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;

  &.small {
    svg {
      max-width: 20px;
      max-height: 25px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    justify-content: center;
    & svg {
      margin: 10px 0;
    }
  }
`

// ===============
//      QUERY
// ===============

const SOCIAL_MEDIA_QUERY = graphql`
  {
    seaSiteInfo {
      social_media {
        social_media_platforms {
          platform_name
          url
        }
      }
    }
  }
`
