/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useContext, useEffect } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

import Header from "../components/header/header"
import MainNavigation from "../components/header/main-navigation"
import ToastNotification from "./toast-notification"
import MobileMenu from "../components/header/mobile-menu"
import Footer from "../components/footer/footer"
import ThemeConfig from "./theme-config"

import "./layout.css"
import CartDrawer from "../drawers/cart-drawer"
import WishlistDrawer from "../drawers/wishlist-drawer"
import Breadcrumb from "../theme/breadcrumb"
import { getProperty } from "../lib/utils"
import AuthContext from "../context/auth/auth-context"
import Banner from "../components/generic/banner"

// ======================
//        COMPONENT
// ======================
const Layout = ({
  children = [],
  location,
  pathContext,
  className = "",
  breadcrumbString = "",
  bannerDetails = null,
}) => {
  // On every page change, check logged in status
  const { checkLoggedInState } = useContext(AuthContext)
  useEffect(() => {
    checkLoggedInState()
  }, [location])

  // Prepare site and breadcrumb information
  const siteTitleQueryData = useStaticQuery(SITE_TITLE_QUERY)
  const breadcrumbPath = getProperty(pathContext, ["breadcrumb", "crumbs"], [])
  const brand = siteTitleQueryData?.seaSiteInfo?.site_brand
  const wishlistEnabled =
    siteTitleQueryData?.seaSiteInfo?.site_feature_flags?.enable_wishlist

  return (
    <ThemeConfig>
      <Header
        siteTitle={siteTitleQueryData.site.siteMetadata.title}
        logo={brand.logo_light.url}
        wishlistEnabled={wishlistEnabled}
      />
      <MainNavigation
        location={location}
        logo={brand.logo_light.url}
        wishlistEnabled={wishlistEnabled}
      />
      <ToastNotification location={location} />
      <MobileMenu
        location={location}
        logo={brand.logo_light.url}
        wishlistEnabled={wishlistEnabled}
      />
      <CartDrawer location={location} />
      {wishlistEnabled && <WishlistDrawer location={location} />}
      {bannerDetails && <Banner details={bannerDetails}></Banner>}
      <Main
        location={location}
        className={`${className} ${
          bannerDetails == null ? "" : "no-top-padding"
        }`}
      >
        <Breadcrumb
          breadcrumb={breadcrumbPath}
          breadcrumbString={breadcrumbString}
          location={location}
        />
        {children}
      </Main>
      <Footer location={location} logo={brand.logo_light.url} />
    </ThemeConfig>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

// ===============
//     STYLES
// ===============
const Main = styled.main`
  margin: 2.2rem auto 6rem;
  & > *:not(.page-fullwidth-section) {
    max-width: ${({ theme }) => theme.maxWidth};
    margin-left: auto;
    margin-right: auto;
    @media (max-width: ${({ theme }) => theme.breakLarge}) {
      max-width: ${({ theme }) => theme.breakMedium};
      margin-left: auto;
      margin-right: auto;
    }
    @media (max-width: ${({ theme }) => theme.breakMedium}) {
      max-width: 100%;
      padding: 0 30px;
    }
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      max-width: 100%;
      padding: 0 25px;
    }
  }
  .hide-breadcrumb {
    display: none;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    padding-top: 2.5rem;

    &.no-top-padding {
      padding-top: 0;
    }
  }
`

// ===============
//      QUERY
// ===============
const SITE_TITLE_QUERY = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    seaSiteInfo {
      site_brand {
        primary_color
        hover_color
        grey_color
        primary_color_light
        secondary_color
        logo_light {
          url
        }
        logo_dark {
          url
        }
      }
      site_feature_flags {
        enable_wishlist
      }
    }
  }
`
