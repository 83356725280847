import React from "react"

function Youtube() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.49"
      height="20.032"
      viewBox="0 0 28.49 20.032"
    >
      <path
        fill="#03111f"
        d="M28.944 7.634A3.58 3.58 0 0026.425 5.1c-2.222-.6-11.131-.6-11.131-.6s-8.909 0-11.131.6a3.58 3.58 0 00-2.518 2.534 37.553 37.553 0 00-.6 6.9 37.553 37.553 0 00.6 6.9 3.526 3.526 0 002.519 2.495c2.222.6 11.131.6 11.131.6s8.909 0 11.131-.6a3.526 3.526 0 002.519-2.495 37.553 37.553 0 00.6-6.9 37.553 37.553 0 00-.6-6.9zM12.381 18.772V10.3l7.446 4.236-7.446 4.236z"
        data-name="Icon awesome-youtube"
        transform="translate(-1.05 -4.5)"
      ></path>
    </svg>
  )
}

export default Youtube
