import React, { memo, useState, useEffect } from "react"
import styled from "styled-components"

import LinkWrapper from "../../lib/link-wrapper"
import SearchInput, { filterSearchResults } from "./search-input"
import SearchDropdown from "./search-dropdown"
import CompactLoader from "../generic/loaders/compact-loader"

const searchPlaceholderText = "Search products or pages..."
const maxSearchCount = 6
const resultTypes = [
  { title: "Products", type: "products" },
  { title: "Categories", type: "categories" },
  { title: "Pages", type: "pages" },
  { title: "Blogs", type: "posts" },
]
const defaultResults = {
  posts: [],
  categories: [],
  products: [],
  pages: [],
}

const SearchBar = memo(() => {
  const [searchContainerFocus, setSearchContainerFocus] = useState(false)
  const [searchResultClicked, setSearchResultClicked] = useState("")
  const [searchQuery, setSearchQuery] = useState("")
  const [searchResponse, setSearchResponse] = useState(null)
  const [searchResults, setSearchResults] = useState(defaultResults)
  const [loading, setLoading] = useState(false)

  // When new search results are received
  const searchCallback = (results, newQuery) => {
    setSearchResults(filterSearchResults(results))
    setSearchResponse(results)
    setSearchQuery(newQuery)
  }

  // Handle a focus loss on the results. Check if a result was clicked
  const handleClick = (e) => {
    if (e?.relatedTarget?.id?.startsWith?.("search-result-hit-")) {
      setSearchResultClicked(e?.relatedTarget?.id?.toString?.() ?? false)
    } else {
      setSearchContainerFocus(false)
    }
  }

  // convenience variable for number of results
  const searchResultCount =
    searchResults.products.length +
    searchResults.posts.length +
    searchResults.pages.length

  return (
    <SearchContainerStyling
      onFocus={() => setSearchContainerFocus(true)}
      onBlur={handleClick}
    >
      <SearchInput
        searchPlaceholderText={searchPlaceholderText}
        callback={searchCallback}
        defaultResults={defaultResults}
        setLoading={setLoading}
      />
      <DropdownStyling>
        {resultTypes.map(({ title, type }) => (
          <SearchDropdown
            key={title}
            title={title}
            searchResults={searchResults[type]}
            searchResultClicked={searchResultClicked}
            searchContainerFocus={searchContainerFocus}
            searchQuery={searchQuery}
            searchResponse={searchResponse}
          />
        ))}
        {!searchContainerFocus ||
        !(searchResultCount >= maxSearchCount) ? null : (
          <StyledLinkWrapper
            id={`search-result-hit-more`}
            key="more"
            to={`/search/?query=${searchQuery}`}
            className={
              searchResultClicked === `search-result-hit-more`
                ? `clicked`
                : `not-clicked`
            }
          >
            <p>
              <em>Click here to view more results</em>
            </p>
          </StyledLinkWrapper>
        )}
        {loading && searchContainerFocus && (
          <LoaderStyling>
            <CompactLoader colour="black" />
          </LoaderStyling>
        )}
      </DropdownStyling>
    </SearchContainerStyling>
  )
})

export default SearchBar

// ==========
// 	 STYLES
// ==========
const SearchContainerStyling = styled.div`
  width: 100%;
  position: relative;
`
const StyledLinkWrapper = styled(LinkWrapper)`
  display: flex;
  align-items: center;
  padding: 20px;
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  &:hover {
    box-shadow: 0 0 25px 0px ${({ theme }) => theme.colors.black1}33;
    position: relative;
    z-index: 101;
  }
  p {
    margin: 0 0 0 20px;
  }
`
const DropdownStyling = styled.div`
  position: absolute;
  z-index: 101;
  width: 100%;
  padding: 0 1px;
  box-shadow: 0 0 25px 0px ${({ theme }) => theme.colors.black1}66;
  border-radius: 15px;
  background-color: white;
  h3 {
    padding: 15px;
    text-align: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightgrey3};
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    top: 60px;
  }
`
const LoaderStyling = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
`
