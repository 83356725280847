import React from "react"
import styled from "styled-components"
import { slugify } from "../../lib/utils"

const FormInputField = ({
  labelName,
  className = "", //optional
  fieldId, //optional
  placeholder = "",
  fieldType = "text",
  required = true,

  // inputValue is required to make this component controlled
  // inputValue is assigned directly to the input's value prop
  inputValue = undefined,

  // onChangeCallback passes the change event directly from the input field
  onChangeCallback = (event) => {},

  // onValueChange passes the input field id and value on each value change
  onValueChange = ({ id, value }) => {},
}) => {
  if (typeof fieldId == "undefined") {
    fieldId = slugify(labelName)
  }

  const onChangeHandler = (event) => {
    onChangeCallback(event)
    onValueChange({ id: fieldId, value: event.target.value })
  }
  return (
    <InputStyles htmlFor={fieldId} className={className}>
      {labelName ? `${labelName}${required ? "*" : ""}` : null}
      {fieldType == "textarea" ? (
        <textarea
          type={fieldType}
          id={fieldId}
          name={fieldId}
          value={inputValue}
          placeholder={placeholder}
          required={required}
          style={{ height: "160px" }}
          onChange={onChangeHandler}
        />
      ) : (
        <input
          type={fieldType}
          id={fieldId}
          name={fieldId}
          value={inputValue}
          placeholder={placeholder}
          required={required}
          onChange={onChangeHandler}
          minLength="3"
        />
      )}
    </InputStyles>
  )
}

export default FormInputField

// ===============
//     STYLES
// ===============
const InputStyles = styled.label`
  input,
  textarea {
    outline: none;
    resize: none;
    background: ${({ theme }) => theme.colors.lightgrey2} 0% 0% no-repeat
      padding-box;
    border: 0;
    border-radius: 2px;
    width: 100%;
    padding: 8px 19px;
    margin: 10px 0px;
    font-size: 16px;
    ::-webkit-input-placeholder {
      color: ${({ theme }) => theme.colors.charcoal};
      font-size: 16px;
    }
    ::-moz-placeholder {
      color: ${({ theme }) => theme.colors.charcoal};
      font-size: 16px;
    }
    :-ms-input-placeholder {
      color: ${({ theme }) => theme.colors.charcoal};
      font-size: 16px;
    }
    :-moz-placeholder {
      color: ${({ theme }) => theme.colors.charcoal};
      font-size: 16px;
    }
  }
`
