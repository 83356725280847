import React, { useContext } from "react"
import styled from "styled-components"

import AuthContext from "../../context/auth/auth-context"

import LinkWrapper from "../../lib/link-wrapper"

import UserIcon from "../../images/icons/user-icon"
import RegisterIcon from "../../images/icons/register"
import LogoutIcon from "../../images/icons/logout"

const AccountLinks = ({ className }) => {
  const { loggedInState, performLogout } = useContext(AuthContext)

  if (loggedInState === true) {
    return (
      <>
        <div>
          <MenuIconStyledLinkWrapper to="/my-account/">
            <UserIcon stroke="white" />
            My Account
          </MenuIconStyledLinkWrapper>
        </div>
        <div>
          <MenuIconStyledLinkWrapper
            onClick={() => performLogout("/")}
            as="div"
          >
            <LogoutIcon fill="white" className="fill-white" />
            Logout
          </MenuIconStyledLinkWrapper>
        </div>
      </>
    )
  }
  return (
    <>
      <div>
        <MenuIconStyledLinkWrapper to="/login/">
          <UserIcon stroke="white" />
          Login
        </MenuIconStyledLinkWrapper>
      </div>
      <div>
        <MenuIconStyledLinkWrapper to="/register/">
          <RegisterIcon fill="white" />
          Register
        </MenuIconStyledLinkWrapper>
      </div>
    </>
  )
}

export default AccountLinks

// ===============
//      STYLES
// ===============
const MenuIconStyledLinkWrapper = styled(LinkWrapper)`
  cursor: pointer;
  display: flex;
  margin: 0 0 0 30px;
  justify-content: space-between;
  align-items: center;
  color: white;
  height: 100%;
  & svg {
    margin-right: 10px;
  }
  &:hover svg g path {
    fill: white;
    transition: all 0.15s ease-in-out;
  }
  & svg g path {
    fill: transparent;
    transition: all 0.15s ease-in-out;
  }

  .fill-white g path {
    fill: white;
  }

  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    margin: 0;
    justify-content: center;
  }
`
