import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import LinkWrapper from "../../lib/link-wrapper"
import CartButton from "./cart-button"
import WishlistButton from "./wishlist-button"

const MainNavigation = ({ location = {}, logo, wishlistEnabled }) => {
  const [isScrollBelowHeader, setIsScrollBelowHeader] = useState(false)
  const mainNavRef = useRef(null)
  const menuQueryData = useStaticQuery(MENU_QUERY)
  const menuItems = menuQueryData?.seaSiteMenuItems?.header_menu

  useEffect(() => {
    //This event checks the height between the main-nav component
    //and the top of the window and below if the window is scrolled
    //below the the top of the main-nav the logo cart and wistlist
    //buttons are faded in
    const handleScroll = () => {
      if (mainNavRef.current) {
        if (mainNavRef.current.getBoundingClientRect().top <= 0) {
          setIsScrollBelowHeader(true)
        } else {
          setIsScrollBelowHeader(false)
        }
      }
    }

    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", () => handleScroll)
    }
  }, [])

  return (
    <MainNavigationStyling ref={mainNavRef}>
      <NavigationSideColumnStyling
        className={`${isScrollBelowHeader ? "sticky" : ""}`}
      >
        <StyledLinkedLogo to="/">
          <img src={logo} />
        </StyledLinkedLogo>
      </NavigationSideColumnStyling>
      <NavigationInnerStyling
        className={`${isScrollBelowHeader ? "sticky" : ""}`}
      >
        {menuItems.map((node, i) => {
          return (
            <MenuItemStyledLinkWrapper
              key={i}
              to={node.url}
              as={node.url ? LinkWrapper : "div"}
            >
              <MenuItemStyledText>{node.title}</MenuItemStyledText>
            </MenuItemStyledLinkWrapper>
          )
        })}
      </NavigationInnerStyling>
      <NavigationSideColumnStyling
        className={`${isScrollBelowHeader ? "sticky" : ""}`}
      >
        {wishlistEnabled && <StyledWishlistButton />}
        <StyledCartButton />
      </NavigationSideColumnStyling>
    </MainNavigationStyling>
  )
}

export default MainNavigation

// ===============
//     STYLES
// ===============

const StyledCartButton = styled(CartButton)`
  .cart-text {
    display: none !important;
  }
  & div svg {
    margin: 0 25px 0 18px;
  }
`

const StyledWishlistButton = styled(WishlistButton)`
  .cart-text {
    display: none !important;
  }
  & div svg {
    margin: 0 20px 0 18px;
  }
`

const MenuItemStyledLinkWrapper = styled(LinkWrapper)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.15s ease-in-out;
  padding: 0 30px;
  :hover {
    transition: all 0.15s ease-in-out;
    background-color: ${({ theme }) => theme.colors.brandHover};
  }
`

const MenuItemStyledText = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  font-family: ${({ theme }) => theme.primaryFont};
  font-size: 1rem;
  text-shadow: ${({ theme }) => theme.boxShadow};
  text-decoration: none;
  cursor: pointer;
  :active {
    font-weight: bold;
  }
`

const NavigationInnerStyling = styled.nav`
  display: flex;
  justify-content: space-around;
  transition: all 0.5s ease-in-out;
  max-width: ${({ theme }) => theme.maxWidth};
  width: 100%;
  height: 100%;
  padding: 0;
  text-transform: uppercase;
  .hide-home-button {
    display: none;
  }
  .sticky& > div {
    @media (max-width: ${({ theme }) => theme.breakLarge}) {
      padding: 0 15px;
    }
  }
`

const MainNavigationStyling = styled.div`
  position: sticky;

  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    display: none;
  }

  top: 0;
  z-index: 100;
  height: 60px;
  background: ${({ theme }) => theme.colors.brandPrimaryLight};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.15s ease-in-out;
`

const StyledLinkedLogo = styled(LinkWrapper)`
  width: 100%;
  margin-left: 20px;
  svg,
  img {
    vertical-align: middle;
    max-height: 40px;
    margin: 0;
  }
`

const NavigationSideColumnStyling = styled.div`
  display: flex;
  justify-content: flex-end;
  max-width: calc(calc(100% / 2) - calc(${({ theme }) => theme.maxWidth} / 2));
  width: 100%;
  min-width: 130px;
  margin: 0;
  overflow: hidden;
  display: none;
  &.sticky {
    display: flex;
  }
`

// ===============
//      QUERY
// ===============

const MENU_QUERY = graphql`
  {
    seaSiteMenuItems {
      header_menu {
        title
        url
      }
    }
  }
`
