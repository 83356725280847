import React from "react"

const UpArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.675"
    height="51.178"
    viewBox="0 0 15.675 51.178"
  >
    <g
      id="Group_3"
      data-name="Group 3"
      transform="translate(-91.162 -6750.322)"
    >
      <line
        id="Line_2"
        data-name="Line 2"
        y1="50"
        transform="translate(99 6751.5)"
        fill="none"
        stroke="#707070"
        strokeWidth="1"
      />
      <path
        id="Path_2"
        data-name="Path 2"
        d="M94,6753.755l7.5-6.869,7.5,6.869"
        transform="translate(-2.5 4.114)"
        fill="none"
        stroke="#707070"
        strokeWidth="1"
      />
    </g>
  </svg>
)

export default UpArrow
