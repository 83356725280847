import React from "react"

const Instagram = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="24.937"
    viewBox="0 0 24.942 24.937"
  >
    <path
      id="Icon_awesome-instagram"
      data-name="Icon awesome-instagram"
      d="M12.469,8.313a6.393,6.393,0,1,0,6.393,6.393A6.383,6.383,0,0,0,12.469,8.313Zm0,10.55a4.157,4.157,0,1,1,4.157-4.157,4.164,4.164,0,0,1-4.157,4.157ZM20.615,8.051A1.491,1.491,0,1,1,19.124,6.56,1.488,1.488,0,0,1,20.615,8.051ZM24.85,9.565A7.38,7.38,0,0,0,22.835,4.34,7.428,7.428,0,0,0,17.61,2.325c-2.059-.117-8.23-.117-10.289,0A7.418,7.418,0,0,0,2.1,4.334,7.4,7.4,0,0,0,.082,9.559c-.117,2.059-.117,8.23,0,10.289A7.38,7.38,0,0,0,2.1,25.073a7.438,7.438,0,0,0,5.225,2.014c2.059.117,8.23.117,10.289,0a7.38,7.38,0,0,0,5.225-2.014,7.428,7.428,0,0,0,2.014-5.225c.117-2.059.117-8.224,0-10.283ZM22.19,22.057a4.208,4.208,0,0,1-2.37,2.37c-1.641.651-5.537.5-7.351.5s-5.715.145-7.351-.5a4.208,4.208,0,0,1-2.37-2.37c-.651-1.641-.5-5.537-.5-7.351s-.145-5.715.5-7.351a4.208,4.208,0,0,1,2.37-2.37c1.641-.651,5.537-.5,7.351-.5s5.715-.145,7.351.5a4.208,4.208,0,0,1,2.37,2.37c.651,1.641.5,5.537.5,7.351S22.841,20.421,22.19,22.057Z"
      transform="translate(0.005 -2.238)"
      fill={fill || "#003A8D"}
    />
  </svg>
)

export default Instagram
