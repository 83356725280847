import React, { useContext, useState } from "react"
import styled from "styled-components"
import CartContext from "../../context/cart/cart-context"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const Coupons = ({ small, onCouponAdded = () => {} }) => {
  const [loading, setLoading] = useState(false)
  const [apiMessage, setApiMessage] = useState("")
  const [apiError, setApiError] = useState("")
  const [value, setValue] = useState("")
  const { addCoupon, removeCoupon, cartMeta } = useContext(CartContext)

  // Attempts to make an API call to add a coupon to the cart
  const handleAdd = () => {
    if (value.length > 0) {
      setLoading(true)
      setApiMessage("")
      setApiError("")
      addCoupon(value)
        .then((resp) => {
          setApiMessage("Coupon added")
          setLoading(false)
        })
        .catch((error) => {
          setApiError(error)
          setLoading(false)
        })
      setValue("")
    }
  }

  // Attempts to make an API call to remove a coupon from the cart
  const handleRemove = (coup) => {
    setLoading(true)
    setApiMessage("")
    setApiError("")
    removeCoupon(coup)
      .then((resp) => {
        setApiMessage("Coupon removed")
        setLoading(false)
      })
      .catch((error) => {
        setApiError(error)
        setLoading(false)
      })
    setValue("")
  }

  return (
    <Wrap className={small && "small"}>
      <Flex>
        {cartMeta.coupons.map((coup, i) => (
          <Badge key={i}>
            <Text>{coup?.coupon?.toUpperCase()}</Text>
            <StyledX onClick={() => handleRemove(coup?.coupon)}>×</StyledX>
          </Badge>
        ))}
      </Flex>
      <Container small={small}>
        <InputSection open={true}>
          <InputWrap
            onSubmit={(e) => {
              e.preventDefault()
              handleAdd()
            }}
          >
            <Input
              type="text"
              placeholder="Add promo code"
              value={value}
              onChange={(e) => {
                e.persist()
                setValue(e.target.value)
              }}
            />
          </InputWrap>
          <button
            className={`${loading ? " loading" : ""}`}
            onClick={handleAdd}
            disabled={loading}
          >
            {loading ? "Applying..." : "Add"}
          </button>
        </InputSection>
      </Container>
      {apiMessage && <div className="message">{apiMessage}</div>}
      {apiError && <div className="error">{apiError}</div>}
    </Wrap>
  )
}
// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================

const Text = styled.div`
  padding-left: 10px;
`
const StyledX = styled.div`
  cursor: pointer;
  padding: 0 7px;
  font-size: larger;
  font-weight: bold;
`
const Badge = styled.div`
  background: ${({ theme }) => theme.colors.brandHover};
  color: #ffffff;
  border-radius: 7px;
  border-bottom: dotted;
  border-top: dotted;
  margin-right: 5px;
  display: flex;
  align-items: center;
  overflow: hidden;
  span.icon {
    cursor: pointer;
    svg {
      transition: 0.2s all ease-in-out;
    }
    &:hover {
      svg {
        transform: scale(1.3);
      }
    }
  }
`
const Wrap = styled.div`
  margin: 0 30px;
  div.error,
  div.message {
    text-align: center;
    margin: 10px 0;
  }

  div.error {
    color: #fd3237;
  }

  div.message {
    color: #48b6dd;
  }
  &.small {
    * {
      font-size: 14px;
    }
    input {
      padding: 5px;
    }
    span.icon {
      padding: 0 5px;
    }
  }
`
const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
`

const Container = styled.div`
  margin: 10px 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  ${({ small }) => small && "font-size: 14px;"}
`
const InputSection = styled.div`
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;

  transition: 0.2s all ease-in-out;
  ${({ open }) => !open && "cursor: pointer;"}
  ${({ open }) => open && "width:100%;"}
	div.text {
    height: 100%;
    ${({ small }) => small && "min-width: 108px;"}
    display: flex;
    align-items: center;
    transition: 0.2s all ease-in-out;
    text-transform: uppercase;
    padding: 5px 10px;
    white-space: pre;
    color: #bbc1c6;
    ${({ open }) => open && "background: #1A293C;"}
    ${({ open }) => open && "color: white;"}

    @media (max-width: ${({ theme }) => theme.breakTiny}) {
      min-width: 83px;
      font-size: 10px;
    }
  }
  border-radius: 0 50px 50px 0;
  span.icon {
    border-left: 2px solid #bbc1c6;
    height: 100%;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    ${({ open }) => open && "background: #1A293C;"}
    ${({ open }) => open && "cursor: pointer;"}
  }
  button {
    background: ${({ theme }) => theme.colors.brandPrimary};
    width: 200px;

    @media (max-width: ${({ theme }) => theme.breakTiny}) {
      width: 100px;
    }
  }
  button.loading {
    background: ${({ theme }) => theme.colors.grey};
  }
  &:hover {
    ${({ open }) => !open && "transform: translateX(3px);"}
  }
`

const InputWrap = styled.form`
  overflow: hidden;
  width: 100%;
  transition: all 0.3s ease-in-out;
`

const Input = styled.input`
  border: none;
  width: 100%;
  padding: 10px;
  &:active,
  &:focus {
    outline: none;
  }
`

export default Coupons
