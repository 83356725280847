import React, { useContext } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import LinkWrapper from "../../lib/link-wrapper"

import AuthContext from "../../context/auth/auth-context"

import { scrollTop } from "../../lib/utils"

import SubscriptionForm from "./subscription-form"

import UpArrowIcon from "../../images/icons/up-arrow"
import PaymentOptionLogos from "./payment-option-logos"

import FooterSocialMedia from "./footer-social-media"
import FooterContactInformation from "./footer-contact-information"

const Footer = ({ location, logo }) => {
  const { loggedInState, performLogout } = useContext(AuthContext)
  // Prepare site information
  const footerQueryData = useStaticQuery(FOOTER_QUERY)
  const footerCategories = footerQueryData.allSeaCategory.nodes
  const newsletterEnabled = footerQueryData.seaSiteInfo?.site_feature_flags?.enable_newsletter_signup

  const skinnyFooterItems =
    footerQueryData?.seaSiteMenuItems?.skinny_footer_menu || []

  const helpfulLinks = {
    title: "Helpful Links",
    rows:
      footerQueryData?.seaSiteMenuItems?.footer_helpful_links_menu.slice(
        0,
        7
      ) || [],
  }

  const categories = {
    title: "Categories",
    rows: footerCategories
      .map((cat, index) => {
        return {
          title: cat.title,
          url: `/${cat.slug}/`,
        }
      })
      .slice(0, 7),
  }

  const myAccount = {
    title: "My Account",
    rows: loggedInState
      ? [
        {
          title: "Manage Account",
          url: "/my-account/",
        },
        {
          title: "Logout",
          url: "/",
          do: () => performLogout("/"),
        },
      ]
      : [
        {
          title: "Login",
          url: "/login/",
        },
        {
          title: "Register",
          url: "/",
        },
      ],
  }

  return (
    <>
      <ContentAreaColumnStyling className="contact-info mobile">
        <LogoStyledLinkWrapper to="/">
          <img src={logo} />
        </LogoStyledLinkWrapper>
        <FooterContactInformation />
      </ContentAreaColumnStyling>
      <MainFooterStyling>
        <FooterColumnStyling>
          <BackToTopStyling onClick={scrollTop}>
            <UpArrowIcon />
            <p>TO TOP</p>
          </BackToTopStyling>
        </FooterColumnStyling>
        <MaxContentAreaStyling>
          <ContentAreaColumnStyling className="contact-info desktop">
            <LogoStyledLinkWrapper to="/">
              <img src={logo} />
            </LogoStyledLinkWrapper>
            <FooterContactInformation />
          </ContentAreaColumnStyling>
          {helpfulLinks.rows.length > 0 ? (
            <ContentAreaColumnStyling>
              <h5>{helpfulLinks.title}</h5>
              {helpfulLinks.rows.map((row, idx) => {
                return (
                  <StyledFooterColumnLink
                    key={`row-${idx}`}
                    to={row.url}
                    onClick={row.do ? row.do : () => { }}
                  >
                    {row.title}
                  </StyledFooterColumnLink>
                )
              })}
            </ContentAreaColumnStyling>
          ) : null}
          {categories.rows.length > 0 ? (
            <ContentAreaColumnStyling>
              <h5>{categories.title}</h5>
              {categories.rows.map((row, idx) => {
                return (
                  <StyledFooterColumnLink
                    key={`row-${idx}`}
                    to={row.url}
                    onClick={row.do ? row.do : () => { }}
                  >
                    {row.title}
                  </StyledFooterColumnLink>
                )
              })}
            </ContentAreaColumnStyling>
          ) : null}
          <ContentAreaColumnStyling>
            <h5>{myAccount.title}</h5>
            {myAccount.rows.map((row, idx) => {
              return (
                <StyledFooterColumnLink
                  key={`row-${idx}`}
                  to={row.url}
                  onClick={row.do ? row.do : () => { }}
                >
                  {row.title}
                </StyledFooterColumnLink>
              )
            })}
          </ContentAreaColumnStyling>
          {newsletterEnabled ?
            <ContentAreaColumnStyling>
              <h5>Want to stay informed?</h5>
              <p>Subscribe to our newsletter below</p>
              <SubscriptionForm location={location} />
            </ContentAreaColumnStyling> : null}
        </MaxContentAreaStyling>
        <FooterColumnStyling>
          <FooterSocialMedia />
        </FooterColumnStyling>
      </MainFooterStyling>
      <SkinnyFooterStyling>
        <AlignLeftSkinnyFooterColumn>
          <span>{`©${new Date().getFullYear()} ${footerQueryData.site.siteMetadata.title
            }. All rights reserved.`}</span>
        </AlignLeftSkinnyFooterColumn>
        <AlignMiddleSkinnyFooterColumn>
          <PaymentOptionLogos />
        </AlignMiddleSkinnyFooterColumn>
        <AlignRightSkinnyFooterColumn>
          {skinnyFooterItems.map((link, idx) => (
            <LinkWrapper key={idx} to={link.url}>
              {link.title}
            </LinkWrapper>
          ))}
        </AlignRightSkinnyFooterColumn>
      </SkinnyFooterStyling>
    </>
  )
}

export default Footer

// ===============
//     STYLES
// ===============
const AlignLeftSkinnyFooterColumn = styled.div`
  width: 39%;
  display: inline-block;
  text-align: left;
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    width: 100%;
    text-align: center;
  }
`

const AlignMiddleSkinnyFooterColumn = styled.div`
  display: inline-flex;
  width: 26%;
  height: 100%;
  margin: auto 0;
  align-items: center;
  justify-content: center;
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    width: 100%;
    margin: 10px 0;
  }
`

const AlignRightSkinnyFooterColumn = styled.div`
  display: inline-flex;
  width: 35%;
  justify-content: space-between;
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    width: 100%;
  }
`

const SkinnyFooterStyling = styled.div`
  max-width: ${({ theme }) => theme.maxWidth};
  margin-left: auto;
  margin-right: auto;
  display: flex;
  padding: 2rem 0;
  height: 100%;
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    max-width: 100%;
    padding: 1rem 30px;
    display: flex;
    flex-direction: column-reverse;
  }
  @media (max-width: 970px) {
    max-width: 100%;
    padding: 0 30px;
    font-size: 0.75rem;
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    max-width: 100%;
    padding: 0 25px;
  }
`

const StyledFooterColumnLink = styled(LinkWrapper)`
  color: ${({ theme }) => theme.colors.grey};
  margin: 0.7rem 0 0;
  display: flex;
  font-size: 1rem;
  line-height: 1.5rem;
  @media (max-width: 970px) {
    text-align: center;
  }
`

const LogoStyledLinkWrapper = styled(LinkWrapper)`
  img {
    max-width: 280px;
  }
  svg {
    margin: 0 0 4vh;
  }
`

const MainFooterStyling = styled.div`
  display: flex;
  justify-content: center;

  background-color: ${({ theme }) => theme.colors.brandGrey};
  h5 {
    color: ${({ theme }) => theme.colors.grey};
    margin-bottom: 0.8rem;
  }
  p {
    color: ${({ theme }) => theme.colors.grey};
  }
  @media (max-width: 970px) {
    padding-top: 48px;
  }
`

const FooterColumnStyling = styled.div`
  max-width: calc(calc(100% / 2) - calc(${({ theme }) => theme.maxWidth} / 2));
  width: 100%;
  padding-top: 4rem;
  padding-bottom: 4rem;
  &:first-child {
    padding-left: 3%;
    padding-right: 6%;
  }
  &:last-child {
    padding-left: 6%;
    padding-right: 3%;
  }
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    &:first-child {
      padding-left: 0;
      padding-right: 8%;
    }
    &:last-child {
      padding-right: 0;
      padding-left: 8%;
    }
  }
`

const ContentAreaColumnStyling = styled.div`
  width: 16.6%;
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-right: 2rem;
  &.contact-info.mobile {
    display: none;
  }
  &.contact-info {
    width: fit-content;
  }
  a {
    text-transform: uppercase;
  }
  &:nth-child(2) {
    min-width: 13.2%;
  }
  &:nth-child(3) {
    min-width: 20%;
  }
  &:first-child,
  &:last-child {
    width: 25%;
  }
  &:first-child {
    width: fit-content;
    margin-right: 3%;
    padding-right: 1rem;
    padding-left: 1rem;
    background: ${({ theme }) => theme.colors.brandPrimary};
  }
  &:last-child {
    padding-right: 0;
  }
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    width: 33%;
    order: 3;
    padding-top: 0;
    &:first-child {
      order: 1;
      margin-right: 7%;
      margin-bottom: 3rem;
      padding-top: 4rem;
      padding-bottom: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &:last-child {
      padding-top: 4rem;
      width: 50%;
      order: 2;
      margin-bottom: 3rem;
    }
  }
  @media (max-width: 970px) {
    width: 100%;
    margin-right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    order: 2;
    margin-right: 0;
    margin-bottom: 3rem;
    padding: 0;
    &.contact-info.mobile {
      display: flex;
      width: 100%;
      margin: 0;
      margin-right: 0;
      background: ${({ theme }) => theme.colors.brandPrimary};
      padding-top: 4rem;
      padding-bottom: 2rem;
      margin-bottom: 0;
    }
    &.contact-info.desktop {
      display: none;
    }
    &:first-child {
      width: 100%;
      order: 1;
      margin-right: 0;
      margin-bottom: 3rem;
    }
    &:last-child {
      width: 100%;
      order: 3;
      margin-bottom: 4rem;
      padding-top: 0;
      h5 {
        text-align: center;
      }
    }
  }
  .cart-icon {
    display: none;
  }
  .footer-drawer-button {
    justify-content: start;
    margin-top: 10px;
  }
`

const MaxContentAreaStyling = styled.div`
  max-width: ${({ theme }) => theme.maxWidth};
  width: 85%;
  display: flex;
  flex-direction: row;
  // flex-wrap: wrap;
  > div {
    :first-child {
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    width: 75%;
    flex-wrap: wrap;
  }
`

const BackToTopStyling = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-items: center;
  margin: auto 0;
  height: 100%;
  cursor: pointer;
  p {
    text-orientation: upright;
    -webkit-text-orientation: upright;
    text-orientation: upright;
    writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    -webkit-writing-mode: vertical-rl;
    text-align-last: start;
    line-height: 1rem;
    letter-spacing: 0.5rem;
  }
  svg {
    margin-top: 0;
    overflow: hidden;
    transition: all ease-in-out 0.2s;
  }
  :hover > svg {
    transition: all ease-in-out 0.2s;
    margin-top: -10px;
    margin-bottom: 10px;
  }
`

// ===============
//      QUERY
// ===============
const FOOTER_QUERY = graphql`
  {
    seaSiteMenuItems {
      footer_helpful_links_menu {
        url
        title
      }
      skinny_footer_menu {
        url
        title
      }
    }
    seaSiteInfo {
      site_feature_flags {
        enable_newsletter_signup
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    # Only parent categories should be shown in footer
    allSeaCategory(filter: { parent_id: { eq: 0 }, featured: { eq: true } }) {
      nodes {
        title
        slug
      }
    }
  }
`
